<template>
    <label class="c-answer-item">
        <input
            :type="inputType"
            :value="value"
            class="check"
            @change="change"
            :name="name"
        >
        <img src="@/assets/square.svg" alt="square" class="checked">
        <img src="@/assets/check-square.svg" alt="square" class="unchecked">
        <span class="text">{{ text }}</span>
    </label>
</template>

<script>
export default {
    props: {
        text: String,
        value: String,
        change: Function,
        name: String,
        isRadio: Boolean,
    },
    computed: {
        inputType() {
            return this.isRadio ? "radio" : "checkbox"
        }  
    },
    data: function() {
        return {}
    }
}
</script>

<style lang="sass">

label.c-answer-item
    display: flex
    align-items: center
    cursor: pointer
    margin-bottom: 15px
    *
        transition: 0.1s

    label.check-wrap
        cursor: pointer

    input.check
        display: none
        &:checked
            ~ img.unchecked
                display: inline-block
            ~ img.checked
                display: none
            ~ span.text
                font-weight: 700

    img.unchecked
        display: none

    span.text
        display: inline-block
        margin-left: 10px
        color: #124C7B

</style>