<template>
    <div class="propertie-item" @click="click(id)">
        <p class="title">{{ title }}</p>
        <p class="address">{{ address }}</p>
    </div>
</template>

<script>
export default {
    props: {
        id: [String, Number],
        title: String,
        address: String,
        click: Function,
    },
    data: function() {
        return {}
    },
}
</script>

<style lang="sass">

div.propertie-item
    box-shadow: inset 0px 0.5px 0px rgba(0, 0, 0, 0.16)
    padding: 10px 0
    cursor: pointer
    transition: 0.1s
    &:hover
        background: #eee

    p
        margin: 0

    p.title
        font-size: 18px
        line-height: 25px
        color: #124C7B

    p.address
        font-size: 14px
        line-height: 20px
        color: #434F5B
    
</style>