<template>
    <div class="c-entries-wrap">
        <label
            v-for="item in entries"
            :key="item.id"
            class="c-answer-item"
        >
            <input
                type="checkbox"
                :value="item.id"
                class="check"
                :checked="item.isChecked"
                @change="handleChange"
            >
            <img src="@/assets/square.svg" alt="square" class="checked">
            <img src="@/assets/check-square.svg" alt="square" class="unchecked">
            <span class="text">{{ item.value }}</span>
        </label>
        <form class="add-entry-wrap" @submit.prevent="addEntry">
            <p class="title">Add Entry</p>
            <div class="body">
                <input
                    type="text"
                    name="value"
                    class="add-entry-inp"
                    placeholder="Type here..."
                >
                <button class="add-entry-btn">
                    <img src="@/assets/blue-plus.svg" alt="plus">
                    <span>ADD</span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { addEntry, selectEntry } from "../../../api/campus"
import { nanoid } from "nanoid"

export default {
    props: {
        entriesProp: Array,
        questionId: [String, Number],
        isChecked: [Number, String],
    },
    data: function() {
        return {
            entries: this.entriesProp
        }
    },
    methods: {
        async addEntry(e) {
            try {
                let inp = [...e.currentTarget.elements][0]
    
                if(inp.value) {
                    let response = await addEntry(this.questionId, { value: inp.value })
                    this.entries.push(response)
                }
    
                inp.value = ""
            } catch (err) {
                console.error(err)
            }
        },
        async handleChange(e) {
            try {
                let isChecked = e.currentTarget.checked
                let entry = this.entries.find(item => item.id == e.currentTarget.value)
                let response = selectEntry(entry.id, { value: isChecked ? 1 : 0 })

            } catch (err) {
                console.error(err)
            }
        },
    }
}
</script>

<style lang="sass">
@import ../../../helpers/mixins

div.c-entries-wrap

    label.c-answer-item
        display: flex
        align-items: center
        cursor: pointer
        margin-bottom: 15px
        *
            transition: 0.1s

        label.check-wrap
            cursor: pointer

        input.check
            display: none
            &:checked
                ~ img.unchecked
                    display: inline-block
                ~ img.checked
                    display: none
                ~ span.text
                    font-weight: 700

        img.unchecked
            display: none

        span.text
            display: inline-block
            color: #124C7B

    form.add-entry-wrap
        margin-bottom: 15px

        div.body
            display: flex
            justify-content: space-between
            +mobile(550px)
                flex-wrap: wrap

                input.add-entry-inp
                    width: 100%

                button.add-entry-btn
                    width: 100%
                    margin-top: 10px
                    padding: 8px

        p.title
            font-size: 14px
            line-height: 146%
            color: #434F5B
            margin: 0 0 8px 0

        input.add-entry-inp
            padding: 11px 12px
            font-size: 18px
            border: 1px solid #7ED3D4
            border-radius: 5px
            outline: none
            width: 80%

        button.add-entry-btn
            background: #7ED3D4
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
            width: 19%
            border: none
            outline: none
            display: flex
            justify-content: center
            align-items: center
            cursor: pointer
            span
                display: inline-block
                margin-left: 10px
                padding: 5px
                color: #124C7B
                font-size: 18px
                font-weight: 500

</style>