<template>
    <div class="v-campus-report">
        <div class="spinner-wrap" v-show="!isDataLoaded">
            <Spinner />
        </div>
        <div class="container" ref="container">
            <div
                class="answers-category"
                :style="`height: ${openCategories.includes('subfields') ? 'auto' : headHeight + 'px'};`"
                v-if="isDataLoaded"
            >
                <div class="head" ref="head">
                    <div class="left">
                        <span class="title">Report Fields</span>
                        <span class="save-text" v-if="isDataSaved">(Saved)</span>
                    </div>
                    <div
                        :class="`arrow ${openCategories.includes('subfields') ? 'open' : ''}`"
                        @click="toggleCategory('subfields')"
                    >
                        <img src="@/assets/green-arrow-down.svg" alt="arrow-down">
                    </div>
                </div>
                <form class="sub-fields-form" @submit.prevent="handleSubmit">
                    <div class="inp-wrap">
                        <p class="inp-title">Possible Next Steps:</p>
                        <textarea name="possible" class="note"></textarea>
                    </div>
                    <div class="inp-wrap">
                        <p class="inp-title">Attachments:</p>
                        <textarea name="attachments" class="note"></textarea>
                    </div>
                    <div class="inp-wrap">
                        <p class="inp-title">Follow Up:</p>
                        <textarea name="followUp" class="note"></textarea>
                    </div>
                    <div class="inp-wrap">
                        <p class="inp-title">Closing Sentence:</p>
                        <textarea name="closingSentence" class="note"></textarea>
                    </div>
                    <div class="inp-wrap">
                        <p class="inp-title">Recommendations:</p>
                        <textarea name="recommendations" class="note"></textarea>
                    </div>
                    <div class="btn-wrap">
                        <button class="btn">
                            <BtnSpinner v-if="isShowBtnSpinner"/>
                            <div v-if="!isShowBtnSpinner" class="content">
                                <span>Save</span>
                                <img src="@/assets/cloud.svg" alt="cloud">
                            </div>
                        </button>
                    </div>
                </form>
            </div>
            <div
                v-for="(item, index) in questions"
                :key="index"
                class="answers-category"
                :style="`height: ${openCategories.includes(item.category) ? 'auto' : headHeight + 'px'};`"
            >
                <div class="head" ref="head">
                    <span class="title">{{ item.category }}</span>
                    <div :class="`arrow ${openCategories.includes(item.category) ? 'open' : ''}`" @click="toggleCategory(item.category)">
                        <img src="@/assets/green-arrow-down.svg" alt="arrow-down">
                    </div>
                </div>
                <div class="property-fields" v-if="item.category === 'Property Information'">
                    <div class="answer-item">
                        <p class="question">Property Name</p>
                        <p class="value">{{ propertyName ? propertyName : "-" }}</p>
                    </div>
                    <div class="answer-item">
                        <p class="question">Address</p>
                        <p class="value">{{ propertyAddress ? propertyAddress : "-" }}</p>
                    </div>
                    <div class="answer-item">
                        <p class="question"># of Buildings</p>
                        <p class="value">{{ numberOfBuildings ? numberOfBuildings : "-" }}</p>
                    </div>
                    <div class="answer-item">
                        <p class="question">Acreage</p>
                        <p class="value">{{ acreage ? acreage : "-" }}</p>
                    </div>
                    <div class="answer-item">
                        <p class="question">Zoning</p>
                        <p class="value">{{ zoning ? zoning : "-" }}</p>
                    </div>
                    <div class="answer-item">
                        <p class="question">Main Property Picture</p>
                        <div class="photos">
                            <div
                                v-for="item in propertyPictures"
                                :key="item.id"
                                class="image-item"
                            >
                                <img :src="item.image" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <AnswerItem
                    v-for="item in item.questions"
                    :key="item.id"
                    :id="item.id"
                    :question="item.question"
                    :dataType="item.dataType"
                    :comment="item.comment"
                    :order="item.order"
                    :category="item.category"
                    :options="item.options"
                    :files="item.files"
                    :images="item.images"
                    :isChecked="item.isChecked"
                />
                <!-- <Question
                    v-for="item in item.questions"
                    :key="item.id"
                    :questionId="item.id"
                    :question="item.question"
                    :dataType="item.dataType"
                    :entriesProp="item.options"
                    :files="item.files"
                    :comment="item.comment"
                    :imagesProp="item.images"
                    :isChecked="item.isChecked"
                /> -->
            </div>
        </div>
    </div>
</template>

<script>
import AnswerItem from "./components/AnwerItem"
import Spinner from "../../components/Spinner"
import { getAnswers, saveReportFields } from "../../api/campus"
import BtnSpinner from "../../components/BtnSpinner"
import Question from "../AddWalkInspection/components/Question"

export default {
    data: function() {
        return {
            propertyName: "",
            propertyAddress: "",
            numberOfBuildings: "",
            acreage: "",
            zoning: "",
            headHeight: "",
            isShowBtnSpinner: false,
            isDataSaved: false,
            questions: [],
            openCategories: [],
            propertyPictures: [],
        }
    },
    components: {
        AnswerItem,
        Spinner,
        BtnSpinner,
        Question,
    },
    async mounted() {
        try {
            let { campusId } = this.$route.params

            let response = await getAnswers(campusId)

            this.$store.commit("header/setData", {
                title: response.propertyName ? response.propertyName : "",
                showPrevBtn: true,
                prevLink: "/campus-assessments",
                showExportBtn: true,
                showCampusEditBtn: true,
            })
            
            setTimeout(() => {
                if(this.$refs["head"])
                    this.headHeight = this.$refs["head"][0].offsetHeight + 20
                else
                    this.headHeight = 84
            }, 1000)

            response.questions.forEach(item => {
                this.openCategories.push(item.category)
            })
            this.propertyName = response.propertyName
            this.propertyAddress = response.propertyAddress
            this.questions = response.questions
            this.propertyPictures = response.propertyPictures
        } catch (err) {
            console.error(err)
        }
    },
    computed: {
        isDataLoaded() {
            return this.questions.length > 0
        }
    },
    methods: {
        async handleSubmit(e) {
            this.isShowBtnSpinner = true
            let data = {}

            ;[...e.currentTarget.elements].forEach(el => {
                if(el.name)
                    data[el.name] = el.value
            })

            console.log(data)

            let response = await saveReportFields(this.$route.params.campusId, data)
            
            this.isShowBtnSpinner = false
            this.isDataSaved = true
        },
        toggleCategory(category) {
            let index = this.openCategories.findIndex(item => item === category)
            
            if(index > -1)
                this.openCategories.splice(index, 1)
            else
                this.openCategories.push(category)
        },
    },
}
</script>

<style lang="sass">

div.v-campus-report
    padding-top: 30px

    div.spinner-wrap
        display: flex
        justify-content: center
        margin-top: 100px

    div.container
        max-width: 600px

    div.answers-category
        overflow: hidden

    div.head
        background: linear-gradient(0deg, rgba(126, 211, 212, 0.2), rgba(126, 211, 212, 0.2)), #FFFFFF
        padding: 10px 12px
        margin-bottom: 20px
        display: flex
        justify-content: space-between
        align-items: center

        span.title
            color: #124C7B
            letter-spacing: 0.02em
            font-size: 24px
            line-height: 150%

        span.save-text
            display: inline-block
            font-size: 18px
            color: #1cb21c
            margin-left: 10px

    div.arrow
        width: 44px
        height: 44px
        border-radius: 50%
        background: #ffffff
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer

        img
            transition: 0.2s
            transform: rotate(-90deg)
        
        &.open
            img
                transform: none

    div.answer-item

        p.question
            margin: 0
            font-size: 20px
            line-height: 140%
            letter-spacing: 0.02em
            color: #434F5B

        p.value
            color: #111111
            font-size: 18px
            line-height: 120%

    div.image-item
        margin: 15px 0

    form.sub-fields-form
        margin-bottom: 15px

        div.inp-wrap
            margin-bottom: 15px

        p.inp-title
            color: #434F5B
            line-height: 146%
            margin: 0
            color: #434F5B
            font-size: 14px
            line-height: 146%
            margin-bottom: 8px

        textarea.note
            resize: none
            min-height: 100px
            width: 100%
            outline: none
            padding: 11px 12px
            font-size: 14px
            border: 1px solid #7ED3D4
            border-radius: 5px
            font-family: "Roboto"

        div.btn-wrap
            display: flex
            justify-content: center
            margin-top: 20px

        button.btn
            width: 100%
            background: #124C7B
            color: #fff
            padding: 10px
            outline: none
            display: flex
            justify-content: center
            border: none
            font-size: 18px
            font-weight: 500
            padding: 16px
            cursor: pointer
            text-transform: uppercase
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
            img
                margin-left: 15px

            div.content
                display: flex
                align-items: center

</style>