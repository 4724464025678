<template>
    <div class="c-head-btn" @click="click">
        <span>{{ text }}</span>
        <img :src="require(`@/assets/${img}`)" alt="img">
    </div>
</template>

<script>
export default {
    props: {
        text: String,
        img: String,
        click: Function,
    },
    data: function() {
        return {}
    }
}
</script>

<style lang="sass">

div.c-head-btn
    display: flex
    justify-content: center
    align-items: center
    padding: 10px
    min-width: 134px
    min-height: 44px
    background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), linear-gradient(0deg, #FFFFFF, #FFFFFF)
    // background: #7ed3d4
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
    cursor: pointer

    span
        color: #124C7B
        text-transform: uppercase
        font-weight: 500
        font-size: 18px
    
    img
        margin-left: 10px

</style>