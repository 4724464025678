import { getProperties, addProperty } from "../api/properties"
import { setProperty } from "../api/campus"

export default {
    namespaced: true,
    state: () => ({
        selectedProperty: {}, // Property data
        properties: [],
        propertyInfo: {
            address: "",
            numberOfBuildings: "",
            propertyPictures: [],
        },
    }),
    mutations: {
        setProperties(state, data) {
            state.properties = data
        },
        setProperty(state, data) {
            state.selectedProperty = data
        },
        setPropertyInfo(state, data) {
            state.propertyInfo = data
        },
        setPropertyPicture(state, data) {
            state.propertyInfo.propertyPictures = []
            state.propertyInfo.propertyPictures.push(data)
        }
    },
    getters: {},
    actions: {
        async addProperty({ commit, state }, data) {
            try {
                let response = await addProperty(data)

                commit("setProperty", response)
                state.properties.push(response)

            } catch (err) {
                console.error(err)
            }
        },
        async getProperties({ commit, state }) {
            try {
                let response = await getProperties()
    
                commit("setProperties", response)
            } catch (err) {
                console.error(err)
            }
        },
        async selectProperty({ commit, state }, data) { // Data: { id: number or string, campusId: number or string, value: number or string }
            let property = state.properties.find(item => item.id === data.id)
            commit("setProperty", property)
            
            if(data.hasOwnProperty("campusId") && state.properties.length > 0) {
                let response = await setProperty(data.campusId, { propertyId: property.id })

                commit("setPropertyInfo", response)
            }

        },
    },
}
