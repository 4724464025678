<template>
    <div class="v-inspection-history">
        <div class="history-container">
            <div
                v-for="item in inspections"
                :key="item.id"
                class="history-item"
            >
                <div class="left">
                    <p>
                        Completed by <span class="name">{{ item.name }}</span> <span class="date">{{ item.date }}</span>
                    </p>
                </div>
                <img src="@/assets/green-arrow-right.svg" alt="arrow">
            </div>
        </div>
    </div>
</template>

<script>
import { nanoid } from "nanoid"

export default {
    data: function() {
        return {
            inspections: [
                {
                    id: nanoid(),
                    name: "John Doe",
                    date: "Wed 25 Nov 2020, 11:13pm",
                },
                {
                    id: nanoid(),
                    name: "John Doe",
                    date: "Wed 25 Nov 2020, 11:13pm",
                },
                {
                    id: nanoid(),
                    name: "John Doe",
                    date: "Wed 25 Nov 2020, 11:13pm",
                },
            ]
        }
    },
    mounted() {
        let { inspectionId } = this.$route.params

        this.$store.commit("header/setData", {
            title: "History of inspections",
            showPrevBtn: true,
            prevLink: `/inspection/${inspectionId}/1`,
        })
    }
}
</script>

<style lang="sass">

div.v-inspection-history
    padding-top: 30px

    div.history-container
        max-width: 600px
        margin: 0 auto

    div.history-item
        display: flex
        justify-content: space-between
        align-items: center
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.16)
        
        p
            margin: 10px 0
            color: #434F5B
            font-size: 12px

        span.name,
        span.date
            font-size: 14px
            color: #124C7B
            line-height: 20px
            
        span.name
            dipslay: inline-block
            margin-right: 10px
            margin-left: 10px
            padding-right: 10px
            border-right: 1px solid #cccccc

</style>