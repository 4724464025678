<template>
    <div class="inspections-history-wrap" :style="`height: ${height}px;`">
        <div class="head" ref="head" @click="toggleList">
            <h2>History of inspections</h2>
            <div class="head-btn">
                <img src="@/assets/history-arrow.svg" alt="arrow" :class="isShowList ? 'open' : ''">
            </div>
        </div>
        <div class="list" ref="list">
            <div
                v-for="item in inspections"
                :key="item.id"
                class="inspection-item"
            >
                <div class="left">
                    <p>
                        Completed by <span class="name">{{ item.name }}</span> <span class="date">{{ item.date }}</span>
                    </p>
                </div>
                <img src="@/assets/green-arrow-right.svg" alt="arrow">
            </div>
        </div>
    </div>
</template>

<script>
import { nanoid } from 'nanoid'
export default {
    data: function() {
        return {
            isShowList: false,
            headHeight: 0,
            listHeight: 0,
            inspections: [
                {
                    id: nanoid(),
                    name: "John Doe",
                    date: "Wed 25 Nov 2020, 11:13pm",
                },
                {
                    id: nanoid(),
                    name: "John Doe",
                    date: "Wed 25 Nov 2020, 11:13pm",
                },
                {
                    id: nanoid(),
                    name: "John Doe",
                    date: "Wed 25 Nov 2020, 11:13pm",
                },
            ]
        }
    },
    mounted() {
        this.headHeight = this.$refs.head.clientHeight
        this.listHeight = this.$refs.list.clientHeight
    },
    computed: {
        height() {
            if (this.isShowList)
                return this.headHeight + this.listHeight  
            else
                return this.headHeight
        },
    },
    methods: {
        toggleList() {
            if(this.isShowList)
                this.isShowList = false
            else
                this.isShowList = true
        }
    },
}
</script>

<style lang="sass">
    
div.inspections-history-wrap
    border-bottom: 2px solid rgba(0, 0, 0, 0.16)
    margin-top: 20px
    overflow: hidden
    transition: 0.1s
    
    div.head
        cursor: pointer
        display: flex
        justify-content: space-between
        align-items: center
        padding-bottom: 10px

        div.head-btn
            width: 44px
            height: 44px
            background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
            display: flex
            justify-content: center
            align-items: center
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
            img
                transition: 0.1s
                &.open
                    transform: rotate(90deg)

        h2
            font-size: 20px
            line-height: 28px
            letter-spacing: 0.02em
            color: #434F5B
            font-weight: 400
            margin: 0

    div.list
        padding-top: 10px

    div.inspection-item
        display: flex
        justify-content: space-between
        align-items: center
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.16)
        p
            margin: 10px 0
            color: #434F5B
            font-size: 12px

    span.name,
    span.date
        font-size: 14px
        color: #124C7B
        line-height: 20px
        
    span.name
        dipslay: inline-block
        margin-right: 10px
        margin-left: 10px
        padding-right: 10px
        border-right: 1px solid #cccccc

</style>