<template>
    <div class="c-button-wrap">
        <button :class="`save-btn ${btnClass}`">
            <span>save and next</span>
            <img src="@/assets/save.svg" alt="save">
        </button>
    </div>
</template>

<script>
export default {
    props: {
        isActive: Boolean,
    },
    computed: {
        btnClass() {
            if(this.isActive)
                return "active"

            return ""
        }
    },
    data: function() {
        return {}
    }
}
</script>

<style lang="sass">

div.c-button-wrap
    display: flex
    justify-content: center
    max-width: 600px
    margin: 0 auto
    margin-top: 30px
    
    button.save-btn
        outline: none
        border: none
        color: #fff
        letter-spacing: 0.04em
        text-transform: uppercase
        display: flex
        justify-content: center
        align-items: center
        padding: 10px
        width: 100%
        font-weight: 500
        line-height: 136%
        font-size: 18px
        min-height: 56px
        background: #CCCCCC
        &.active
            background: #124C7B
            cursor: pointer
        
    img
        margin-left: 10px

</style>