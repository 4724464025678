<template>
    <div class="v-pdf-report">
        <div class="spinner-wrap" v-show="isShowSpinner">
            <Spinner />
        </div>
        <div class="container" v-show="!isShowSpinner">
            <div class="page1 page">
                <h2 class="main-title">
                    Campus Assessment
                    <br>
                    and
                    <br>
                    Site Report
                </h2>
                <p class="property tac">{{ property }}</p>
                <div class="image-wrap property-image">
                    <img :src="propertyImage" alt="property" v-if="propertyImage">
                </div>
                <div class="logos">
                    <div class="image-wrap logo-wrap">
                        <img src="@/assets/logo.png" alt="logo">
                    </div>
                    <div class="image-wrap">
                        <img :src="clientLogo" alt="client-logo" v-if="clientLogo">
                    </div>
                </div>
                <div class="district">
                    <p class="property-district tac">{{ propertyDistrict }}</p>
                    <p class="tac">District</p>
                </div>
            </div>
            <div class="page2 page">
                <p class="tac">Wesley Letterhead</p>
                <p>Dear Rev. Mursten,</p>
                <p>
                    Wesley Community Development Corp appreciates the opportunity to visit the St. Paul 
                    campus and
                </p>
                <p>
                    We are providing this assessment as a tool for
                </p>
                <p>
                    The assessment (explanation of assessment) 
                    Campus Assessment
                </p>
            </div>
            <div class="page3 page">
                <p class="tac">
                    Campus Assessment
                    <br>
                    {{ property }}
                    <br>
                    {{ date }}
                </p>
                <p class="tac">Observations</p>
                <p class="observations-text">{{ observationAnswer }}</p>
                <div
                    v-for="image in observationImages"
                    :key="image.id"
                    class="observation-item"
                >
                    <img :src="image.image" alt="image">
                    <p class="descr">{{ image.note }}</p>
                </div>
            </div>
            <div class="page page4">
                <p class="tac">Executive Summary</p>
                <div class="field-item">
                    <span>Recommendations</span>
                    <p class="recommendations-text">{{ recommendations }}</p>
                </div>
                <div class="field-item">
                    <span>Possible Next Steps</span>
                    <p>{{ possibleNextSteps }}</p>
                </div>
                <div class="field-item">
                    <span>Attachments</span>
                    <p>{{ attachments }}</p>
                </div>
                <div class="field-item">
                    <span>Follow Up</span>
                    <p>{{ followUp }}</p>
                </div>
                <div class="field-item">
                    <span>Closing sentence</span>
                    <p>{{ closingSentence }}</p>
                </div>
                <p class="text">
                    With Best Regards,
                    <br>WESLEY COMMUNITY DEVELOPMENT CORPORATION 
                </p>
                <p class="text">
                    Joel A. Gilland
                    <br>President
                    <br>Chief Executive Officer
                </p>
                <p class="text">cc: District Superintendent</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getAnswers, createReport } from "../../api/campus"
import Spinner from "../../components/Spinner"

export default {
    data: function() {
        return {
            property: "",
            propertyImage: "",
            propertyAddress: "",
            propertyDistrict: "",
            observationAnswer: "",
            recommendations: "",
            possibleNextSteps: "", 
            attachments: "",
            followUp: "",
            closingSentence: "",
            date: "",
            clientLogo: "",
            observationImages: [],
            isShowSpinner: true,
        }
    },
    components: {
        Spinner,
    },
    async mounted() {
        try {
            let { campusId } = this.$route.params

            let response = await getAnswers(campusId)

            let observation = response.questions.find(item => item.category === "General Observations").questions[0]
            let observationImages = observation.images

            this.property = response.propertyName
            this.propertyImage = response.propertyPictures.length > 0 ? response.propertyPictures[0].image : ""
            this.propertyAddress = response.propertyAddress
            this.propertyDistrict = response.district
            this.observationAnswer = observation.comment
            this.observationImages = observationImages
            this.recommendations = response.recommendations
            this.possibleNextSteps = response.possibleNextSteps
            this.attachments = response.attachments
            this.followUp = response.followUp
            this.closingSentence = response.closingSentence
            this.date = response.date
            this.clientLogo = response.clientLogo
            this.isShowSpinner = false

            // let fileResponse = await createReport(campusId)

            // console.log("File:", fileResponse)


        } catch (err) {
            console.error(err)
        }
    },
    methods: {
        async getImage(imagePath) {
            console.log(await fetch(imagePath))
        },
        async startCapture(displayMediaOptions) {
            let captureStream = null

            try {
                captureStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
            } catch(err) {
                console.error("Error: " + err)
            }
            console.log(captureStream)
        }
    }
}
</script>

<style lang="sass">
    
// header.main-head
//     display: none

div.v-pdf-report
    padding-bottom: 50px
    background: #eee
    padding-top: 20px
    min-height: 100vh

    div.spinner-wrap
        display: flex
        justify-content: center
        margin-top: 100px

    div.page
        background: #fff
        margin-bottom: 20px
        min-height: 100vh
        padding: 25px 35px

    div.page1
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column

    div.logos
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0 155px
        width: 100%

        div.image-wrap
            max-width: 150px
            img
                width: 100%

    p.property
        margin-bottom: 0 0 50px 0

    div.property-image
        margin-bottom: 50px

    h2.main-title
        text-align: center
        margin-top: 0
        font-weight: 400
        margin-bottom: 50px

    div.image-wrap
        display: flex
        justify-content: center
        img
            max-width: 400px

    // div.logo-wrap
    //     margin-top: 50px

    div.observation-item
        display: flex
        margin-bottom: 20px
        
        img
            max-width: 20%
            margin-right: 15px

        p.descr
            margin: 0
            border: 1px solid #000
            padding: 10px
            flex-grow: 1

    div.field-item
        span
            font-weight: 700
        p
            font-size: 16px

    p.observations-text,
    p.recommendations-text
        border: 1px solid #000
        padding: 10px

    p.recommendations-text
        min-height: 100px

    p.text
        font-size: 14px

    .tac
        text-align: center

</style>