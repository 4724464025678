export default {
    namespaced: true,
    state: () => ({
        title: "",
        description: "",
        isShowLogo: false,
        isShowSaveBtn: false,
        isShowLogoutBtn: false,
        isShowPrevBtn: false,
        isShowHistoryBtn: false,
        isShowEditBtn: false,
        isShowExportBtn: false,
        isShowEditCampusBtn: false,
        isShowBackBtn: false,
        saveBtnState: "", // or active
        prevLink: "",
        isShowSaveBtnSpinner: false,
        saveBtnHandler: () => {},
    }),
    mutations: {
        setData(state, data) {
            let {
                showLogo,
                showSaveBtn,
                title,
                description,
                showLogoutBtn,
                showPrevBtn,
                prevLink,
                showHistoryBtn,
                saveBtnState,
                saveBtnHandler,
                showSaveBtnSpinner,
                showEditBtn,
                showExportBtn,
                showCampusEditBtn,
                showBackBtn,
            } = data

            state.isShowLogo = showLogo ? true : false
            state.isShowSaveBtn = showSaveBtn ? true : false
            state.isShowLogoutBtn = showLogoutBtn ? true : false
            state.isShowHistoryBtn = showHistoryBtn ? true : false
            state.title = title ? title : ""
            state.description = description ? description : ""
            state.isShowPrevBtn = showPrevBtn ? true : false
            state.prevLink = prevLink ? prevLink : ""
            state.saveBtnState = saveBtnState ? saveBtnState : ""
            state.isShowSaveBtnSpinner = showSaveBtnSpinner ? showSaveBtnSpinner : false
            state.isShowEditBtn = showEditBtn ? showEditBtn : false
            state.isShowEditCampusBtn = showCampusEditBtn ? showCampusEditBtn : false
            state.isShowExportBtn = showExportBtn ? showExportBtn : false
            state.isShowBackBtn = showBackBtn ? showBackBtn : false
            //Handlers
            state.saveBtnHandler = typeof saveBtnHandler === "function" ?  saveBtnHandler : () => {}
        },
    },
    getters: {},
    actions: {}
}