<template>
    <div class="v-add-inspection">
        <div class="container">
            <div class="properties-wrap">
                <PropertyActions />
            </div>
            <div class="inspection-types">
                <p class="description">
                    Choose Inspection Type(s)
                    <sup>*</sup>
                </p>
                <AnswerItem
                    v-for="(item, index) in answersList"
                    name="type"
                    :key="index"
                    :text="item.text"
                    :value="item.value"
                    :change="handleChange"
                    :isRadio="true"
                />
            </div>
            <Button
                text="save"
                rightImg="save.svg"
                :click="handleSubmit"
                :isActive="isActiveBtn"
                :isShowSpinner="isShowBtnSpinner"
            />
        </div>
    </div>
</template>

<script>
import AnswerItem from "./components/AnswerItem"
import Button from "../../components/Button"
import PropertyActions from "../../components/PropertyActions"
import { createInspection } from "../../api/inspections"
import { nanoid } from "nanoid"

export default {
    data: function() {
        return {
            isShowBtnSpinner: false,
            answersList: [
                {
                    id: nanoid(),
                    text: "Initial Property Inspection Report",
                    value: "Initial Property Inspection Report",
                },
                {
                    id: nanoid(),
                    text: "Cemetery Inspection Report",
                    value: "Cemetery Inspection Report",
                },
                {
                    id: nanoid(),
                    text: "Routine Property Inspection Report",
                    value: "Routine Property Inspection Report",
                },
                {
                    id: nanoid(),
                    text: "Leased Property Inspection Report",
                    value: "Leased Property Inspection Report",
                },
                {
                    id: nanoid(),
                    text: "Housing Community Inspection",
                    value: "Housing Community Inspection",
                },
            ],
            answer: "",
        }
    },
    components: {
        AnswerItem,
        Button,
        PropertyActions,
    },
    async mounted() {

        this.$store.commit("header/setData", {
            title: "Add inspection",
            showSaveBtn: true,
            showPrevBtn: true,
            prevLink: "/regular-inspections",
            saveBtnHandler: () => {}
        })

        document.addEventListener("click", this.handleClick)
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClick)
    },
    computed: {
        isActiveBtn() {
            if(this.answer.length > 0 && Object.keys(this.$store.state.properties.selectedProperty).length > 0) {
                this.$store.commit("header/setData", {
                    title: "Add inspection",
                    showSaveBtn: true,
                    showPrevBtn: true,
                    prevLink: "/regular-inspections",
                    saveBtnHandler: this.handleSubmit,
                    saveBtnState: "active"
                })
                return true
            } else {
                this.$store.commit("header/setData", {
                    title: "Add inspection",
                    showSaveBtn: true,
                    showPrevBtn: true,
                    prevLink: "/regular-inspections",
                    saveBtnHandler: this.handleSubmit,
                    saveBtnState: ""
                })
            }
        },
    },
    methods: {
        async handleSubmit() {
            let { id } = this.$store.state.properties.selectedProperty

            this.isShowBtnSpinner = true
            this.$store.commit("header/setData", {
                title: "Add inspection",
                showSaveBtn: true,
                showPrevBtn: true,
                prevLink: "/regular-inspections",
                saveBtnHandler: this.handleSubmit,
                saveBtnState: "active",
                showSaveBtnSpinner: true,
            })
            if(!this.isActiveBtn)
                return false

            try {
                let response = await createInspection({ propertyId: id, type: this.answer })

                this.$store.commit("popups/show", "reportSaved")
            } catch (err) {
                console.error(err)
            }

            this.$store.commit("header/setData", {
                title: "Add inspection",
                showSaveBtn: true,
                showPrevBtn: true,
                prevLink: "/regular-inspections",
                saveBtnHandler: this.handleSubmit,
                saveBtnState: "active",
                showSaveBtnSpinner: false,
            })
            this.isShowBtnSpinner = false
        },
        handleChange(e) {
            this.answer = e.currentTarget.value
        },
        handleClick() {
            this.isShowOpenList = false
        },
    },
}
</script>

<style lang="sass">
@import ../../helpers/mixins

div.v-add-inspection
    padding-top: 48px
    padding-bottom: 50px

    div.properties-wrap
        position: relative

    div.container
        max-width: 900px
        margin: 0 auto

    div.propertie-item
        box-shadow: inset 0px 0.5px 0px rgba(0, 0, 0, 0.16)
        padding: 10px 0
        cursor: pointer
        transition: 0.1s
        &:hover
            background: #eee

        p
            margin: 0

        p.title
            font-size: 18px
            line-height: 25px
            color: #124C7B

        p.address
            font-size: 14px
            line-height: 20px
            color: #434F5B

    div.inspection-types
        margin: 30px 0
        padding: 30px 0
        border-top: 1px solid rgba(18, 76, 123, 0.2)
        border-bottom: 1px solid rgba(18, 76, 123, 0.2)

    p.description
        margin-top: 0
        margin-bottom: 20px
        font-size: 14px
        line-height: 20px
        sup
            font-size: 20px
            color: #EB5757

</style>