import Vue from "vue"
import VueRouter from "vue-router"
import SignIn from "../views/SignIn"
import RegularInspections from "../views/RegularInspections"
import WalkInspections from "../views/WalkInspections"
import AddInspection from "../views/AddInspection"
import Inspection from "../views/Inspection"
import InspectionRevision from "../views/InspectionRevision"
import InspectionHistory from "../views/InspectionHistory"
import Start from "../views/Start"
import AddWalkInspection from "../views/AddWalkInspection"
import CampusReport from "../views/CampusReport"
import CampusPDFReport from "../views/CampusPDFReport"

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "SignIn",
        component: SignIn,
    },
    {
        path: "/regular-inspections",
        name: "RegularInspections",
        component: RegularInspections,
    },
    {
        path: "/campus-assessments",
        name: "WalkInspections",
        component: WalkInspections,
    },
    {
        path: "/campus-assessment/:action/:campusId/:step",
        name: "AddWalkInspection",
        component: AddWalkInspection,
    },
    // {
    //     path: "/edit-campus-assessment/:step/:campusId",
    //     name: "AddWalkInspection",
    //     component: AddWalkInspection,
    // },
    {
        path: "/start",
        name: "Start",
        component: Start,
    },
    {
        path: "/add-inspection",
        name: "AddInspection",
        component: AddInspection,
    },
    {
        path: "/inspection/:inspectionId/:page",
        name: "Inspection",
        component: Inspection,
    },
    {
        path: "/revision/:inspectionId",
        name: "InspectionRevision",
        component: InspectionRevision,
    },
    {
        path: "/inspection-history/:inspectionId",
        name: "InspectionHidtory",
        component: InspectionHistory,
    },
    {
        path: "/campus-assessments/:campusId/:step",
        name: "CampusReport",
        component: CampusReport,
    },
    {
        path: "/campus-assessment/create-report/:campusId",
        name: "CampusPDFReport",
        component: CampusPDFReport,
    },
];

const router = new VueRouter({
    mode: 'hash',
    base: "/",
    routes
});

export default router;
