<template>
    <div class="files-wrap">
        <div class="files">
            <div
                v-for="item in files"
                :key="item.id"
                class="file-item"
            >
                <img src="@/assets/file-icon.svg" alt="file" class="file-icon">
                <span>{{ item.filename }}</span>
                <img src="@/assets/trash-icon.svg" alt="delete" class="delete-img" @click="deleteFile(item.id)">
            </div>
        </div>
        <label class="upload-file-button">
            <input type="file" @change="uploadFile">
            <div class="btn">
                <img src="@/assets/blue-plus.svg" alt="plus">
                <span>Add file</span>
            </div>
        </label>
    </div>
</template>

<script>
import Labels from "./Labels"
import { uploadFile, deleteFile } from "../../../api/campus"
import { nanoid } from "nanoid"

export default {
    props: {
        questionId: [String, Number],
        filesProp: Array,
    },
    data: function() {
        return {
            files: this.filesProp, // { id: number or string, filename: string }
        }
    },
    components: {
        Labels,
    },
    methods: {
        async uploadFile(e) {
            let inp = e.currentTarget
            let file = inp.files[0]
            let reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onloadend = async () => {
                let response = await uploadFile(this.questionId, { filename: file.name, file: reader.result })

                this.files.push(response)
            }

            inp.value = ""
        },
        async deleteFile(fileId) {
            try {
                let response = await deleteFile(fileId)
                let index = this.files.findIndex(item => item.id === fileId)

                this.files.splice(index, 1)
            } catch (err) {
                console.error(err)
            }
        }
    },
}
</script>

<style lang="sass">

div.files-wrap
    display: flex
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap

    label.upload-file-button

        input
            display: none

        div.btn
            display: inline-flex
            justify-content: center
            align-items: center
            min-height: 44px
            min-width: 144px
            border-radius: 28px
            padding: 5px
            background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
            cursor: pointer
            img
                margin-right: 10px

    div.file-item
        background: #F8F8F8
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        border-radius: 28px
        display: inline-flex
        justify-content: center
        align-items: center
        padding: 12px 28px
        color: #124C7B
        margin-right: 10px
        margin-bottom: 10px
        img.file-icon
            margin-right: 12px
        img.delete-img
            margin-left: 12px
            cursor: pointer
        span
            font-weight: 500
            font-size: 18px
            line-height: 136%
            letter-spacing: 0.04em
            text-transform: uppercase

</style>