<template>
    <div class="c-radios-wrap">
        <QuestionBtn
            :id="questionId"
            text="yes"
            :isActive="isChecked"
            :onclick="handleChange"
        />
        <QuestionBtn
            :id="questionId"
            text="no"
            :isActive="!isChecked"
            :onclick="handleChange"
        />
    </div>
</template>

<script>
import QuestionBtn from "../../Inspection/components/QuestionButton"
import { setCheckAnswer } from "../../../api/campus"

export default {
    props: {
        questionId: [String, Number],
        isChecked: Number,
    },
    data: function() {
        return {}
    },
    components: {
        QuestionBtn
    },
    methods: {
        async handleChange(id, text) {
            try {
                let response = await setCheckAnswer(this.questionId, { result: text === "yes" })
            } catch (err) {
                console.error(err)
            }
        }
    },
}
</script>

<style lang="sass">

div.c-radios-wrap
    display: flex
    justify-content: space-between
    margin-top: 20px
    margin-bottom: 20px

    label.c-question-btn
        width: 49%
        div.button
            width: 100%

</style>