import Request from "../helpers/Request"
import config from "../config"

export const createInspection = async (data) => new Request().post(
    config.pathAPI + `/inspections`,
    data
)

export const getInspections = async () => new Request().get(
    config.pathAPI + `/inspections`
)

export const getInspection = async (inspectionId) => new Request().get(
    config.pathAPI + `/inspections/${inspectionId}`
)

export const setAnswer = async (questionId, data) => new Request().put(
    config.pathAPI + `/inspections/answers/${questionId}`,
    data
)

export const uploadImage = async (questionId, data) => new Request().post(
    config.pathAPI + `/inspections/questions/image/${questionId}`,
    data
)

export const deleteImage = async (imageId) => new Request().delete(
    config.pathAPI + `/inspections/questions/image/${imageId}`,
)

export const saveNotes = async (inspectionId, data) => new Request().put(
    config.pathAPI + "/inspections/questions/notes",
    data
)

export const getCompletedInspection = async (inspectionId) => new Request().get(
    config.pathAPI + `/inspections/completed/${inspectionId}`,
)