<template>
    <div :class="`c-answer-btn ${color}`">
        <img src="@/assets/white-check.svg" alt="check" v-show="text === 'pass'">
        <img src="@/assets/white-x.svg" alt="check" v-show="text === 'fail'">
        <img src="@/assets/white-info.svg" alt="check" v-show="text === 'n/a'">
        <span>{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        text: String,
    },
    data: function() {
        return {}
    },
    computed: {
        color() {
            if(this.text) {
                switch(this.text.toLowerCase()) {
                    case "pass":
                        return "blue"
                    case "fail":
                        return "red"
                    case "n/a":
                        return "gray"
                }
            }

            return ""
        }
    },
}
</script>

<style lang="sass">

div.c-answer-btn
    text-transform: uppercase
    min-width: 160px
    min-height: 48px
    padding: 5px
    display: inline-flex
    justify-content: center
    align-items: center
    color: #fff
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
    font-size: 18px
    display: none
    &.blue,
    &.red,
    &.gray
        display: inline-flex

    img
        margin-right: 8px

    &.blue
        background: #124C7B

    &.red
        background: #AF292E

    &.gray
        background: #434F5B

</style>