<template>
    <div class="question-item">
        <p class="question">
            <span>{{ order }}.</span>
            {{ question }}
        </p>
        <div class="images">
            <div
                v-for="(item, index) in images"
                :key="index"
                class="image-item"
            >   
                <div class="delete-btn" @click="deleteImage(item.id)">
                    <img src="@/assets/trash.svg" alt="trash">
                </div>
                <img :src="item.img" alt="image">
            </div>
        </div>
        <UploadButton
            text="take a photo"
            name="image"
            img="aperture.svg"
            :change="uploadFile"
        />
        <div class="answers">
            <QuestionButton
                v-for="(item, index) in buttons"
                :key="index"
                :id="item.id"
                :text="item.text"
                :isActive="item.isActive"
                :onclick="buttonClick"
            />
        </div>
        <textarea
            name="note"
            placeholder="Type here..."
            class="note"
            @change="handleChange"
            v-model="note"
        ></textarea>
    </div>
</template>

<script>
import QuestionButton from "./QuestionButton"
import UploadButton from "@/components/UploadButton"
import { uploadImage, deleteImage } from "../../../api/inspections"

export default {
    props: {
        id: [String, Number],
        index: [String, Number],
        question: String,
        answer: String,
        propNote: String,
        order: [String, Number],
        propImages: Array,
        buttonClick: Function,
        noteHandler: Function,
    },
    data: function() {
        return {
            buttons: [],
            images: this.propImages,
            note: this.propNote,
        }
    },
    components: {
        QuestionButton,
        UploadButton,
    },
    mounted() {
        let answer = this.answer.toLowerCase()

        this.buttons = [
            {
                id: this.id,
                text: "pass",
                isActive: answer === "pass",
            },
            {
                id: this.id,
                text: "fail",
                isActive: answer === "fail",
            },
            {
                id: this.id,
                text: "n/a",
                isActive: answer === "n/a",
            },
        ]
    },
    methods: {
        handleChange(e) {
            this.noteHandler(e, this.id)
        },
        uploadFile(e) {
            try {
                let inp = e?.currentTarget
                let file = inp?.files[0]
                let reader = new FileReader()

                reader.readAsDataURL(file)
    
                reader.onload = async () => {
                    let response = await uploadImage(
                        this?.id,
                        {
                            filename: file?.name,
                            image: reader?.result?.split(",")[1]
                        })

                    this.images.push(response?.image)
                }

                reader.onerror = async (err) => {
                    console.log("ERROR:", err)
                }
    
                inp.value = ""
            } catch (err) {
                console.error(err)
            }
        },
        async deleteImage(id) {
            try {
                let response = await deleteImage(id)
                let index = this.images.findIndex(item => item.id === id)
    
                if(index >= 0)
                    this.images.splice(index, 1)

            } catch (err) {
                console.error(err)
            }
        },
    },
}
</script>

<style lang="sass">
@import ../../../helpers/mixins

div.question-item

    p.question
        font-size: 20px
        letter-spacing: 0.02em
        color: #434F5B

    div.answers
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 20px
        margin-bottom: 20px
        +mobile(550px)
            flex-direction: column
            label
                width: 100%
                margin-bottom: 10px
            div.button
                width: 100%

    textarea.note
        border: 1px solid #7ED3D4
        border-radius: 5px
        padding: 14px 12px
        font-size: 18px
        width: 100%
        min-height: 128px
        outline: none
        resize: none

    div.images
        margin-bottom: 10px
        img
            width: 100%

    div.image-item
        position: relative
        margin-bottom: 15px

    div.delete-btn
        position: absolute
        left: -15px
        top: -15px
        width: 44px
        height: 44px
        background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
        border: 1px solid #FFFFFF
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08)
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        img
            width: 60%

</style>