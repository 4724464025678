<template>
    <header class="main-head" v-show="!isHideHeader">
        <div class="header-container">
            <div class="left">
                <router-link :to="$store.state.header.prevLink" class="prev-page-btn" v-show="headData.isShowPrevBtn">
                    <img src="@/assets/arrow-left.svg" alt="arrow">
                </router-link>
                <img
                    v-show="headData.isShowLogo"
                    src="@/assets/logo.png"
                    alt="logo"
                    class="logo"
                >
                <div
                    :class="`info ${isShowHeadInfo && headData.isShowLogo ? 'border' : ''}`"
                    v-show="isShowHeadInfo"
                >
                    <h2 class="head-title" v-show="headData.title">{{ headData.title }}</h2>
                    <p class="head-description" v-show="headData.description">{{ headData.description }}</p>
                </div>
            </div>
            <div class="right">
                <HeadBtn
                    v-show="headData.isShowLogoutBtn"
                    text="log out"
                    img="log-out.svg"
                    :click="logout"
                />
                <router-link
                    :to="`/campus-assessments/${this.$route.params.campusId}/1`"
                    class="edit-btn"
                    v-show="headData.isShowBackBtn"
                >
                    <img src="@/assets/return.svg" alt="return">
                    <span>Back to Report</span>
                </router-link>
                <router-link
                    :to="`/inspection-history/${this.$route.params.inspectionId}`"
                    class="history-btn"
                    v-show="headData.isShowHistoryBtn"
                >
                    <img src="@/assets/history.svg" alt="history">
                    <span>History</span>
                </router-link>
                <div
                    :class="`save-btn ${headData.saveBtnState}`"
                    v-show="headData.isShowSaveBtn"
                    @click="headData.saveBtnHandler"
                >   
                    <BtnSpinner v-show="headData.isShowSaveBtnSpinner"/>
                    <span v-show="!headData.isShowSaveBtnSpinner">Save</span>
                    <img src="@/assets/save.svg" alt="save" v-show="!headData.isShowSaveBtnSpinner">
                </div>
                <router-link
                    :to="`/campus-assessment/edit/${this.$route.params.campusId}/1`"
                    class="edit-btn"
                    v-show="headData.isShowEditCampusBtn"
                >
                    <img src="@/assets/edit.svg" alt="edit">
                    <span>Edit</span>
                </router-link>
                <div
                    class="export-btn"
                    v-show="headData.isShowExportBtn"
                    @click="exportHandler"
                >
                    <BtnSpinner v-if="isShowExportSpinner"/>
                    <div class="btn-content" v-if="!isShowExportSpinner">
                        <span>export pdf</span>
                        <img src="@/assets/export.svg" alt="export-arrow">
                    </div>
                </div>
                <router-link
                    :to="`/inspection/${this.$route.params.inspectionId}/1`"
                    class="history-btn"
                    v-show="headData.isShowEditBtn"
                >
                    <img src="@/assets/edit.svg" alt="edit">
                    <span>Edit</span>
                </router-link>
            </div>
        </div>
    </header>
</template>

<script>
import { createReport } from "../api/campus"
import HeadBtn from "./HeadBtn"
import BtnSpinner from "../components/BtnSpinner"

export default {
    data: function() {
        return {
            isShowExportSpinner: false,
            isHideHeader: false,
        }
    },
    components: {
        HeadBtn,
        BtnSpinner,
    },
    mounted() {
        let currentPath = this.$router.history.current.path
        let regexp = new RegExp(/\/campus-assessment\/create-report\/\d*/, "g")

        if(currentPath.match(regexp))
            this.isHideHeader = true
    },
    computed: {
        headData() {
            return this.$store.state.header
        },
        isShowHeadInfo() {
            return this.headData.title || this.headData.description
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/signout')
            this.$router.push("/")
        },
        async exportHandler() {
            this.isShowExportSpinner = true
            let fileResponse = await createReport(this.$route.params.campusId)

            this.isShowExportSpinner = false
        },
    },
}
</script>

<style lang="sass">
@import ../helpers/mixins
    
header.main-head
    padding: 15px
    box-shadow: inset 0px -1px 0px rgba(18, 76, 123, 0.24)

    div.header-container
        max-width: 1000px
        margin: 0 auto
        display: flex
        justify-content: space-between
        align-items: center

    img.logo
        +mobile(500px)
            max-width: 100px

    div.left
        display: flex
        align-items: center

        h2,
        p
            margin: 0

        div.info
            margin-left: 15px
            padding-left: 15px
            +mobile(550px)
                display: none
            &.border
                border-left: 1px solid #7ED3D4

        h2.head-title
            font-size: 22px
            line-height: 25px
            color: #124C7B
            font-weight: 400
            
        p.head-description
            font-size: 14px
            line-height: 20px
            color: #111111

    a.prev-page-btn
        background: #7ed3d4
        min-width: 44px
        min-height: 44px
        margin-right: 15px
        padding: 10px
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        text-decoration: none

    div.save-btn
        display: flex
        justify-content: center
        align-items: center
        min-width: 103px
        min-height: 44px
        background: #CCCCCC
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        color: #fff
        text-transform: uppercase
        font-size: 18px
        font-weight: 500
        cursor: default
        transition: 0.1s
        img
            margin-left: 10px
        &.active
            background: #124C7B
            cursor: pointer

    div.right
        display: flex
        align-items: center

    a.history-btn,
    a.edit-btn
        display: flex
        align-items: center
        text-decoration: none
        padding: 10px 12px
        background: #7ed3d4
        font-size: 18px
        letter-spacing: 0.04em
        text-transform: uppercase
        color: #124C7B
        margin-right: 15px
        cursor: pointer
        line-height: 20px
        img
            margin-right: 10px
            max-width: 25px

    a.edit-btn
        font-weight: 500
        box-shadow: 0px 1px 3px RGB(0 0 0 / 16%)

    div.export-btn
        min-width: 167px
        // min-height: 56px
        min-height: 44px
        padding: 5px
        display: flex
        justify-content: center
        align-items: center
        text-transform: uppercase
        color: #124C7B
        letter-spacing: 0.04em
        font-weight: 500
        cursor: pointer
        // background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
        background: #7ed3d4
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        font-size: 18px

        div.btn-content
            display: flex
            align-items: center

        img
            margin-left: 10px

</style>