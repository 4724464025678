<template>
    <div>
        <div id="app">
            <Header />
            <router-view />
            <transition name="fade">
                <ReportSent v-if="popups.reportSent"/>
            </transition>
            <transition name="fade">
                <ReportSaved v-if="popups.reportSaved"/>
            </transition>
            <transition name="fade">
                <InspectionCompleted v-if="popups.inspectionCompleted"/>
            </transition>
            <transition name="fade">
                <CancelInspection v-if="popups.cancelInspection"/>
            </transition>
            <transition name="fade">
                <AddProperty v-if="popups.addProperty" />
            </transition>
            <transition name="fade">
                <BeforeExport v-if="popups.beforeExport" />
            </transition>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header"
import ReportSent from "@/popups/ReportSent"
import ReportSaved from "@/popups/ReportSaved"
import InspectionCompleted from "@/popups/InspectionCompleted"
import CancelInspection from "@/popups/CancelInspection"
import AddProperty from "@/popups/AddProperty"
import BeforeExport from "@/popups/BeforeExport"


export default {
    name: "App",
    title: 'Portal App',
    data: function() {
        return {}
    },
    metaInfo: {
        title: 'Portal App',
    },
    components: {
        Header,
        ReportSent,
        ReportSaved,
        InspectionCompleted,
        CancelInspection,
        AddProperty,
        BeforeExport,
    },
    async mounted() {
        // if(screen.width < 1000)
        //     window.open("https://wesleyscripts.com")

        let isSignin = this.$store.getters["auth/isLogged"]
        if(!localStorage) {
            alert("Please update your browser")
            return false
        }
        let userData = JSON.parse(localStorage.getItem("data"))
        let accessToken = localStorage.getItem("accessToken")
        let isPathMatch = this.$route.path === "/"

        if(userData && accessToken) {
            await this.$store.commit("auth/setUserData", userData)
            await this.$store.commit("auth/setTokens", { accessToken })
        }
        
        if(userData && accessToken) {
            return false
        } else {
            this.$store.dispatch("auth/signout")
        }

        // let isLoginPage = this.$router.currentRoute === "/"

        // let isReport = this.$router.currentRoute

        // if(!isSignin && !isPathMatch)
        //     this.$router.push("/")
    },
    beforeUpdate() {
        let isSignin = this.$store.getters["auth/isLogged"]
        let isPathMatch = this.$route.path === "/"

        if(!isSignin && !isPathMatch)
            this.$router.push("/")
    },
    computed: {
        popups() {
            return this.$store.state.popups
        }
    },
    methods: {},
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap')
@import ./helpers/mixins

body,
html
    margin: 0
    padding: 0
    font-family: "Roboto", sans-serif

.container
    max-width: 1000px
    margin: 0 auto
    padding: 0 15px

*
    box-sizing: border-box
    &:after,
    &:before
        box-sizing: border-box

button
    font-family: "Roboto", sans-serif

.fade-enter-active, .fade-leave-active
    transition: opacity .5s

.fade-enter, .fade-leave-to
    opacity: 0

div.u-popup-wrap
    position: fixed
    left: 0
    top: 0
    bottom: 0
    right: 0
    background: rgba(0,0,0,0.6)
    width: 100%
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    z-index: 1000
    +mobile(650px)
        padding-bottom: 100px
        overflow: auto

</style>
