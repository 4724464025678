<template>
    <router-link :to="link" class="c-inspection-item">
        <div class="data">
            <p class="title">{{ title }}</p>
            <p class="address">{{ address }}</p>
            <div class="bottom">
                <span class="bold">{{ type }}</span>
                <span class="date" v-show="date">
                    Completed by <span class="bold">{{ date }}</span>
                </span>
            </div>
        </div>
        <img src="@/assets/arrow-right.svg" alt="arrow" class="arrow">
    </router-link>
</template>

<script>
export default {
    props: {
        id: [String, Number],
        title: String,
        address: String,
        date: [String, Number],
        type: String,
        isInspected: Boolean,
    },
    data: function() {
        return {}
    },
    computed: {
        link() {
            if(this.isInspected)
                return `/revision/${this.id}`
            else
                return `/inspection/${this.id}/1`
        }
    },
}
</script>

<style lang="sass">

a.c-inspection-item
    padding: 10px 15px
    border-bottom: 1px solid rgba(18, 76, 123, 0.2)
    text-decoration: none
    display: flex
    justify-content: space-between
    align-items: center
    
    p
        margin: 0

    p.title
        font-size: 18px
        color: #124C7B
        line-height: 25px
        margin: 0

    p.address
        font-size: 14px
        color: #434F5B
        line-height: 20px

    span.bold
        font-weight: 500
        font-size: 14px
        color: #434F5B
        
    span.date
        font-size: 14px
        color: #434F5B
        display: inline-block
        padding-left: 5px
        margin-left: 5px
        border-left: 1px solid #CCCCCC
        span.bold
            color: #111111

</style>