<template>
    <div class="c-labels-wrap">
        <div
            v-for="(item, index) in labels"
            :key="index"
            class="label-item"
        >
            <span>#{{ item }}</span>
            <img src="@/assets/red-x.svg" alt="x" class="delete-img" @click="deleteLabel(index)">
        </div>
        <div class="c-select">
            <div class="inp-wrap" @click.stop="openBody">
                <input type="text" class="inp" v-model="labelValue" placeholder="Type here...">
                <img src="@/assets/arrow-down.svg" alt="arrow">
            </div>
            <ul class="select-list" v-if="isShowSelectBody">
                <li
                    v-for="(item, index) in filteredLabelList"
                    :key="index"
                    @click="addLabel"
                >
                    {{ item }}
                </li>
            </ul>
        </div>
        <div class="add-btn" @click="addCastomLabel">
            <img src="@/assets/white-plus.svg" alt="plus">
            <span class="btn-text">Add</span>
        </div>
    </div>
</template>

<script>
import { updateChoices } from "../../../api/campus"
import { nanoid } from 'nanoid'

export default {
    props: {
        imageId: [String, Number],
        labelsProp: Array,
        selectedLabels: Array,
    },
    data: function() {
        return {
            labels: this.selectedLabels, // { id: number or string, text: string }
            labelsList: this.labelsProp,
            isShowSelectBody: false,
            labelValue: "",
        }
    },
    computed: {
        filteredLabelList() {
            return this.labelsList.filter(item => toString(item).toLowerCase().includes(this.labelValue))
        }
    },
    methods: {
        async addLabel(e) {
            let value = e.currentTarget.innerHTML

            this.labels.push(value)
            let response = await updateChoices(this.imageId, { value: this.labels.join(";") })
        },
        async deleteLabel(index) {
            this.labels.splice(index, 1)
            let response = await updateChoices(this.imageId, { value: this.labels.join(";") })
        },
        openBody() {
            document.addEventListener("click", this.documentClick)
            this.isShowSelectBody = !this.isShowSelectBody
        },
        documentClick() {
            this.isShowSelectBody = false
        },
        async addCastomLabel() {
            this.labels.push(this.labelValue)
            let response = await updateChoices(this.imageId, { addedValue: this.labelValue, value: this.labels.join(";") })

            this.labelValue = ""
        },
    }
}
</script>

<style lang="sass">

div.c-labels-wrap
    display: flex
    align-items: center
    flex-wrap: wrap
    margin: 15px 0

    div.label-item
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        border-radius: 28px
        background: #F8F8F8
        padding: 10px 16px
        display: inline-flex
        justify-content: center
        align-items: center
        white-space: nowrap
        margin-right: 10px
        margin-bottom: 10px

        span
            color: #124C7B
            letter-spacing: 0.04em
            line-height: 136%
            font-size: 18px

        img
            margin-left: 14px
            cursor: pointer

    div.c-select
        position: relative

        div.inp-wrap
            position: relative
            margin-bottom: 0
            img
                position: absolute
                top: 50%
                right: 10px
                transform: translateY(-50%)
                max-width: 24px

        input.inp
            padding: 11px 30px 11px 11px !important
            border: 1px solid #7ED3D4
            border-radius: 5px
            outline: none
            max-width: 150px
            margin-bottom: 0

        ul.select-list
            width: 100%
            list-style: none
            margin: 0
            padding: 0
            position: absolute
            border-radius:  0 0 5px 5px
            border-right: 1px solid #7ED3D4
            border-left: 1px solid #7ED3D4
            border-bottom: 1px solid #7ED3D4
            background: #fff
            z-index: 1000

        li
            padding: 10px
            transition: 0.2s
            cursor: pointer
            &:hover
                background: #eee

    div.add-btn
        font-size: 18px
        background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
        display: flex
        justify-content: center
        align-items: center
        border-radius: 28px
        padding: 10px 16px
        text-transform: uppercase
        margin-left: 10px
        font-weight: 500
        cursor: pointer

        img
            margin-right: 10px

        span.btn-text
            color: #fff
            margin-bottom: 0

</style>