<template>
    <div class="u-popup-wrap">
        <div class="container">
            <div class="popup report-sent-popup">
                <div class="img-wrap">
                    <img src="@/assets/help-circle.svg" alt="send">
                </div>
                <p class="text">
                    Are you sure, you want to cancel inspection?
                </p>
                <div class="btns-wrap">
                    <div class="btn red" @click="noHandler">
                        no
                    </div>
                    <div class="btn blue" @click="yesHandler">
                        yes
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../components/Button"

export default {
    data: function() {
        return {}
    },
    components: {
        Button,
    },
    methods: {
        noHandler() {
            this.$store.commit("popups/hide", { name: "cancelInspection" })
        },
        yesHandler() {
            this.$store.commit("popups/hide", { name: "cancelInspection" })
        },
    },
}
</script>

<style lang="sass">
    
div.report-sent-popup
    background: #fff
    padding: 50px
    max-width: 700px

    div.img-wrap
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 40px

    p.text
        font-size: 24px
        text-align: center
        color: #434F5B
        margin-top: 0
        margin-bottom: 40px
        letter-spacing: 0.02em

    div.btns-wrap
        display: flex
        justify-content: center
        align-items: center

    div.btn
        font-size: 18px
        text-transform: uppercase
        min-width: 170px
        min-height: 48px
        display: flex
        justify-content: center
        align-items: center
        padding: 5px
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        margin: 0 12px
        cursor: pointer
        font-weight: 500

        &.red
            border: 2px solid #AF292E
            color: #AF292E

        &.blue
            border: 2px solid #124C7B
            color: #124C7B

</style>