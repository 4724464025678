import store from './index'
import { getCampusQuestions } from "../api/campus"

export default {
    namespaced: true,
    state: () => ({
        questions: [],
    }),
    mutations: {
        setQuestions(state, data) {
            state.questions = data
        },
        clearData(state) {
            state.questions = []
        }
    },
    getters: {
        step1: (state, getters) => {
            if(state.questions.length > 0)
                return state.questions.filter(item => item.category === "Property Information")
            else
                return []
        },
        step2: (state, getters) => {
            if(state.questions.length > 0)
                return state.questions.filter(item => item.category === "Church Information")
            else
                return []
        },
        step3: (state, getters) => {
            if(state.questions.length > 0)
                return state.questions.filter(item => item.category === "Site Inspection")
            else
                return []
        },
        step4: (state, getters) => {
            if(state.questions.length > 0)
                return state.questions.filter(item => item.category === "Interior Inspection")
            else
                return []
        },
        step5: (state, getters) => {
            if(state.questions.length > 0)
                return state.questions.filter(item => item.category === "General Observations")
            else
                return []
        },
    },
    actions: {
        async updateQuestions({ commit, state }, data) {
            try {
                let { campusId } = data
                let response = await getCampusQuestions(campusId)
                
                commit("setQuestions", response.questions)
                if(response.propertyId)
                    store.dispatch("properties/selectProperty", { id: response.propertyId, campusId })
            } catch (err) {
                console.error(err)
            }
        }
    },
}
