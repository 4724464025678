import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApollo from "vue-apollo"
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueMeta from 'vue-meta'

const httpLink = createHttpLink({ uri: 'http://localhost:4000' })
const cache = new InMemoryCache()
const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
})
Vue.use(VueApollo)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.log = console

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
