<template>
    <div class="v-start-page">
        <img src="@/assets/sign-in-bg.jpg" alt="image" class="main-img">
        <div class="inspections-types">
            <router-link to="/regular-inspections" class="inspection-type">
                <span>Inspections</span>
                <img src="@/assets/arrow-right.svg" alt="arrow">
            </router-link>
            <router-link to="/campus-assessments" class="inspection-type">
                <span>Campus Assessments</span>
                <img src="@/assets/arrow-right.svg" alt="arrow">
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {}
    },
    mounted() {
        setTimeout(() => {
            let { fullname, email } = this.$store.state.auth.userData
            this.$store.commit("header/setData", {
                title: `Hello, inspector ${fullname}`,
                description: `${email}`,
                showLogo: true,
                showLogoutBtn: true,
            })
        }, 0)
    },
}
</script>

<style lang="sass">

div.v-start-page
    max-width: 1000px
    margin: 0 auto
    padding-bottom: 100px

    img.main-img
        width: 100%

    div.inspections-types
        max-width: 600px
        margin: 20px auto 0 auto

    a.inspection-type
        display: flex
        justify-content: space-between
        align-items: center
        text-decoration: none
        padding: 25px 15px
        border-bottom: 1px solid rgba(18, 76, 123, .2)
        
        &:last-child
            border-bottom: none
        
        span
            color: #124C7B
            font-size: 18px
            line-height: 142%

</style>