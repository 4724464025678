<template>
    <div class="c-question-wrap">
        <p class="question">{{ question }}</p>
        <div class="inp-wrap" v-if="dataType.includes('Text') || dataType.includes('Number')">
            <input
                type="text"
                class="inp"
                :name="questionId"
                v-model="commentValue"
                placeholder="Type here..."
            >
        </div>
        <textarea
            class="message"
            :name="questionId"
            v-if="dataType.includes('Text Area')"
            v-model="commentValue"
        ></textarea>
        <Radios
            v-if="dataType.includes('Radio')"
            :questionId="questionId"
            :isChecked="isChecked"
        />
        <Entries
            :entriesProp="entriesProp"
            v-if="dataType.includes('Checkbox')"
            :questionId="questionId"
        />
        <Photos
            v-if="dataType.includes('Image')"
            :imagesProp="imagesProp"
            :questionId="questionId"
            :isNotes="question === 'General Observations'"
        />
        <Files
            v-if="dataType.includes('File')"
            :questionId="questionId"
            :filesProp="files"
        />
        <P class="value" v-if="dataType.includes('Auto')">{{ comment }}</P>
    </div>
</template>

<script>
import Radios from "./Radios"
import Entries from "./Entries"
import Photos from "./Photos"
import Files from "./Files"
import { nanoid } from "nanoid"

export default {
    props: {
        questionId: [String, Number],
        question: String,
        comment: String,
        dataType: [String, Array],
        entriesProp: Array,
        files: Array,
        imagesProp: Array,
        isChecked: Number,
    },
    data: function() {
        return {
            commentValue: this.comment,
        }
    },
    components: {
        Radios,
        Entries,
        Photos,
        Files,
    },
    computed: {
        entries() {
            if(this.dataType === "Checkbox") {
                let result = this.entriesProp.filter(item => item.value).map(item => {
                    return {
                        id: item.id,
                        text: item.value,
                        value: item.value,
                        answerId: item.answerId,
                    }
                })
    
                return result
            }

            return []
        },
    },
}
</script>

<style lang="sass">

div.c-question-wrap
    margin-bottom: 20px

    p.question
        color: #434F5B
        letter-spacing: 0.02em
        line-height: 140%
        font-size: 20px
        display: inline-block
        margin-bottom: 15px
        margin-top: 0
    
    div.inp-wrap
        margin-bottom: 15px

        p.inp-title
            font-size: 14px
            line-height: 146%
            color: #434F5B
            margin: 0 0 8px 0

        input.inp
            padding: 11px 12px
            font-size: 18px
            border: 1px solid #7ED3D4
            border-radius: 5px
            outline: none
            width: 100%

    textarea.message
        min-height: 128px
        width: 100%
        resize: none
        border: 1px solid #7ED3D4
        border-radius: 5px
        background: #fff
        font-size: 18px
        line-height: 120%
        outline: none
        padding: 10px
        margin-top: 10px

</style>