import Vue from "vue";
import Vuex from "vuex";
import header from "./header"
import popups from "./popups"
import auth from "./auth"
import properties from "./properties"
import questions from "./questions"

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    modules: {
        header,
        popups,
        auth,
        properties,
        questions,
    },
    getters: {},
    mutations: {},
    actions: {},
});
