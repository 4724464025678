<template>
    <div class="v-inspection-revision">
        <div class="spinner-wrap" v-show="isShowSpinner">
            <Spinner />
        </div>
        <div v-show="!isShowSpinner">
            <div class="revision-container">
                <div class="top">
                    <div class="left">
                        <p class="title">Completed:</p>
                        <p class="value">{{ dateCompleted ? dateCompleted : "" }}</p>
                    </div>
                    <div class="right">
                        <p class="title">Updated:</p>
                        <p class="value">Jun 2, 3:49 AM</p>
                    </div>
                </div>
                <div class="images">
                    <img src="@/assets/test.jpg" alt="test-image">
                </div>
                <div class="tabs">
                    <div
                        :class="`tab ${generalTabState}`"
                        @click="activeTab = 'general'"
                    >
                        <span>General</span>
                    </div>
                    <div
                        :class="`tab ${propertyTabState}`"
                        @click="activeTab = 'property'"
                    >
                        <span>Property</span>
                    </div>
                    <div
                        :class="`tab ${exteriorTabState}`"
                        @click="activeTab = 'exterior'"
                    >
                        <span>exterior</span>
                    </div>
                    <div
                        :class="`tab ${interiorTabState}`"
                        @click="activeTab = 'interior'"
                    >
                        <span>interior</span>
                    </div>
                </div>
                <div class="list">
                    <div
                        class="question"
                        v-for="question in items"
                        :key="question.id"
                    >
                        <span>{{ question.order }}.</span>
                        <div class="right">
                            <p class="question-text">{{ question.question }}</p>
                            <Answer :text="question.answer"/>
                            <p class="comment" v-show="question.note">
                                Comment: {{ question.note }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Answer from "./components/Answer"
import { getCompletedInspection } from "../../api/inspections"
import Spinner from "../../components/Spinner"

export default {
    data: function() {
        return {
            activeTab: "general", // for example: "general" / "property" / "exterior" / "interior"
            dateCompleted: 0,
            isShowSpinner: false,
            questions: {
                general: [],
                property: [],
                exterior: [],
                interior: [],
            }
        }
    },
    components: {
        Answer,
        Spinner,
    },
    computed: {
        generalTabState() {
            if(this.activeTab === "general")
                return "active"

            return ""
        },
        propertyTabState() {
            if(this.activeTab === "property")
                return "active"

            return ""
        },
        exteriorTabState() {
            if(this.activeTab === "exterior")
                return "active"

            return ""
        },
        interiorTabState() {
            if(this.activeTab === "interior")
                return "active"

            return ""
        },
        items() {
            switch(this.activeTab) {
                case "general":
                    return this.questions.general
                case "property":
                    return this.questions.property
                case "exterior":
                    return this.questions.exterior
                case "interior":
                    return this.questions.interior
            }
        },
    },
    async mounted() {
        try {
            this.isShowSpinner = true
            let response = await getCompletedInspection(this.$route.params.inspectionId)

            this.$store.commit("header/setData", {
                title: response.address,
                description: response.type,
                showEditBtn: true,
                showPrevBtn: true,
                prevLink: "/regular-inspections",
            })

            this.dateCompleted = response.dateCompleted
            this.questions = response.questions

            this.isShowSpinner = false
        } catch (err) {
            console.error(err)
        }
    },
}
</script>

<style lang="sass">
@import ../../helpers/mixins

div.v-inspection-revision
    padding: 0 15px 50px 15px

    div.revision-container
        max-width: 600px
        margin: 0 auto

    div.images
        img
            width: 100%

    div.top
        display: flex
        align-items: center
        margin-top: 30px
        margin-bottom: 20px
        div.left
            padding-right: 22px
            margin-right: 22px
            border-right: 1px solid #CCCCCC
        p
            margin: 0
            font-size: 14px

        p.title
            color: #434F5B
            line-height: 20px

        p.value
            color: #000000

    div.tabs
        margin-top: 20px
        display: flex
        justify-content: space-around
        align-items: center
        flex-wrap: wrap

        div.tab
            text-transform: uppercase
            color: #434F5B
            font-weight: 500
            line-height: 16px
            padding: 14px 16px
            box-shadow: inset 0px -1px 0px #7ED3D4
            letter-spacing: 0.75px
            cursor: pointer
            transition: 0.1s
            text-align: center
            +mobile(550px)
                width: 48%
            &.active
                color: #124C7B
                box-shadow: inset 0px -2px 0px #124C7B

    div.list
        margin-top: 36px

    div.question
        display: flex
        align-items: flex-start
        color: #434F5B
        letter-spacing: 0.02em
        line-height: 28px
        font-size: 20px
        margin-top: 30px
        
        div.right
            padding-left: 10px

        p.question-text
            margin: 0
            margin-bottom: 20px
    
    p.comment
        margin-bottom: 0

    div.spinner-wrap
        display: flex
        justify-content: center
        align-items: center
        margin-top: 100px

</style>