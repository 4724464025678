<template>
    <div class="u-popup-wrap">
        <div class="container">
            <form
                class="popup before-export-popup"
                @submit.prevent="handleSubmit"
            >
                <div class="popup-head">
                    <div class="left">
                        <div class="close" @click="closePopup">
                            <img src="@/assets/blue-x.svg" alt="x">
                        </div>
                    </div>
                    <button class="save-btn">
                        <span>Save</span>
                        <img src="@/assets/cloud.svg" alt="cloud">
                    </button>
                </div>
                <div class="popup-body">
                    <div class="inp-group">
                        <div class="inp-wrap">
                            <p class="inp-title">Possible Next Steps:</p>
                            <input
                                type="text"
                                class="inp"
                                name="possible"
                            >
                        </div>
                        <div class="inp-wrap">
                            <p class="inp-title">Attachments:</p>
                            <input
                                type="text"
                                class="inp"
                                name="attachments"
                            >
                        </div>
                    </div>
                    <div class="inp-group">
                        <div class="inp-wrap">
                            <p class="inp-title">Follow Up:</p>
                            <input
                                type="text"
                                class="inp"
                                name="followUp"
                            >
                        </div>
                        <div class="inp-wrap">
                            <p class="inp-title">Closing Sentence:</p>
                            <input
                                type="text"
                                class="inp"
                                name="closingSentence"
                            >
                        </div>
                    </div>
                    <div class="inp-wrap w100">
                        <p class="inp-title">Recommendations:</p>
                        <textarea name="recommendations" class="note"></textarea>
                    </div>
                    <div class="btn-wrap">
                        <button class="btn">
                            <span>Save</span>
                            <img src="@/assets/cloud.svg" alt="cloud">
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { saveReportFields } from "../api/campus"

export default {
    data: function() {
        return {}
    },
    methods: {
        async handleSubmit(e) {
            let data = {}

            ;[...e.currentTarget.elements].forEach(el => {
                if(el.name)
                    data[el.name] = el.value
            })

            let response = await saveReportFields(this.$route.params.campusId, data)
            console.log(response)
            this.$store.commit("popups/hide", "beforeExport")
            this.$router.push(`/campus-assessment/create-report/${this.$route.params.campusId}`)
        },
        closePopup() {
            this.$store.commit("popups/hide", "beforeExport")
        },
    }
}
</script>

<style lang="sass">
    
form.before-export-popup
    background: #fff
    // padding: 25px 50px
    min-width: 500px

    div.inp-group
        display: flex
        justify-content: space-between

    div.popup-head
        padding: 15px
        display: flex
        justify-content: space-between
        align-items: center
        box-shadow: inset 0px -1px 0px rgba(18, 76, 123, 0.24)

    div.close
        width: 44px
        height: 44px
        display: flex
        justify-content: center
        align-items: center
        padding: 5px
        background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        cursor: pointer

    div.left
        display: flex
        align-items: center
        
    button.save-btn
        display: flex
        justify-content: center
        align-items: center
        color: #fff
        outline: none
        border: none
        font-size: 18px
        font-weight: 500
        text-transform: uppercase
        padding: 10px 12px
        cursor: pointer
        background: #124C7B
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        img
            margin-left: 10px

    div.popup-body
        padding: 25px 50px
    
    div.inp-wrap
        width: 49%
        margin-bottom: 20px

        &.w100
            width: 100%

        p.inp-title
            color: #434F5B
            line-height: 146%
            margin: 0
            color: #434F5B
            font-size: 14px
            line-height: 146%
            margin-bottom: 8px

        input.inp
            width: 100%
            border: 1px solid #7ED3D4
            border-radius: 5px
            font-size: 18px
            padding: 11px 12px
            outline: none

    textarea.note
        resize: none
        min-height: 100px
        width: 100%
        outline: none
        padding: 11px 12px
        font-size: 14px
        border: 1px solid #7ED3D4
        border-radius: 5px
        font-family: "Roboto"

    div.btn-wrap
        display: flex
        justify-content: center
        margin-top: 20px

    button.btn
        width: 100%
        background: #124C7B
        color: #fff
        padding: 10px
        outline: none
        display: flex
        justify-content: center
        align-items: center
        border: none
        font-size: 18px
        font-weight: 500
        padding: 16px
        cursor: pointer
        text-transform: uppercase
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        img
            margin-left: 15px

</style>