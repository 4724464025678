<template>
    <button :class="`c-btn ${classBtn} ${btnState}`" @click="click">
        <BtnSpinner v-show="isShowSpinner"/>
        <div class="content-wrap" v-show="!isShowSpinner">
            <img :src="require(`@/assets/${leftImg ? leftImg : ''}`)" alt="image" class="left-img" v-if="leftImg">
            <span class="text">{{ text }}</span>
            <img :src="require(`@/assets/${rightImg ? rightImg : ''}`)" alt="image" class="right-img" v-if="rightImg">
        </div>
    </button>
</template>

<script>
import BtnSpinner from "../components/BtnSpinner"

export default {
    props: {
        text: String,
        rightImg: String,
        leftImg: String,
        stateBtn: [ Boolean, String, Number ],
        classBtn: String,
        type: String,
        isActive: Boolean,
        click: Function,
        isShowSpinner: Boolean,
    },
    data: function() {
        return {}
    },
    components: {
        BtnSpinner,
    },
    computed: {
        btnState() {
            return this.stateBtn || this.isActive ? "active" : ""
        }
    },
}
</script>

<style lang="sass">
    
button.c-btn
    background: #CCCCCC
    color: #fff
    border: none
    width: 100%
    padding: 16px
    display: flex
    justify-content: center
    align-items: center
    cursor: default
    margin-top: 40px
    transition: 0.1s
    outline: none

    &.active
        background: #124C7B
        cursor: pointer

    span
        font-size: 18px
        text-transform: uppercase
        font-weight: 500

    img.left-img
        margin-right: 10px

    img.right-img
        margin-left: 10px

    div.content-wrap
        display: flex
        justify-content: center
        align-items: center

</style>