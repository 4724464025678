<template>
    <div class="v-regular-inspections">
        <div class="start-page-wrap">
            <img src="@/assets/sign-in-bg.jpg" alt="image" class="main-img">
            <div class="bottom-content">
                <Button
                    text="Add inspection"
                    rightImg="white-plus.png"
                    classBtn="add-inspection-btn"
                    :isActive="true"
                    :click="() => $router.push('/add-inspection')"
                />
                <div class="list-head">
                    <div class="left">
                        <div class="search-wrap">
                            <input
                                type="text"
                                class="search-inp"
                                placeholder="Search..."
                                v-model="searchValue"
                            >
                            <img src="@/assets/magnifier.png" alt="magnifier" class="magnifier-img">
                        </div>
                    </div>
                    <div class="right">
                        <span class="sort-text">Sort by</span>
                        <div
                            :class="`c-sort-item ${isSortByName}`"
                            @click="changeSortValue('byName')"
                        >
                            <span>Name</span>
                            <img src="@/assets/arrow-down.png" alt="arrow">
                        </div>
                        <div
                            :class="`c-sort-item ${isSortByDate}`"
                            @click="changeSortValue('byDate')"
                        >
                            <span>Date</span>
                            <img src="@/assets/arrow-down.png" alt="arrow">
                        </div>
                    </div>
                </div>
                <div class="tabs">
                    <div
                        :class="`tab ${propertiesTabState}`"
                        @click="activeTab = 'properties'"
                    >
                        <span>Properties to inspect</span>
                    </div>
                    <div
                        :class="`tab ${inspectedTabState}`"
                        @click="activeTab = 'inspected'"
                    >
                        <span>Inspected last month</span>
                    </div>
                </div>
                <div class="inpections-list">
                    <div class="properties">
                        <ListItem
                            v-for="item in filteredItems"
                            :key="item.id"
                            :id="item.id"
                            :title="item.name"
                            :type="item.type"
                            :address="item.address"
                            :date="item.date"
                            :isInspected="isInspected"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button"
import ListItem from "./components/ListItem"
import { getInspections } from "../../api/inspections"

export default {
    data: function() {
        return {
            sortValue: "",
            activeTab: "properties", // value: properties or inspected
            searchValue: "",
            properties: [],
        }
    },
    components: {
        Button,
        ListItem,
    },
    async mounted() {
        try {
            let response = await getInspections()
            this.properties = response
        } catch (err) {
            console.error(err)
        }

        this.$store.commit("header/setData", {
            title: "Regular Inspections",
            showLogo: true,
            showPrevBtn: true,
            prevLink: "/start"
        })
    },
    computed: {
        isSortByName() {
            return this.sortValue === "byName" ? "active" : ""
        },
        isSortByDate() {
            return this.sortValue === "byDate" ? "active" : ""
        },
        inspectedItems() {
            return this.properties.filter(item => item.date)
        },
        propertiesItems() {
            return this.properties.filter(item => !item.date)
        },
        isInspected() {
            return this.activeTab === "inspected"
        },
        propertiesTabState() {
            if(this.activeTab === "properties")
                return "active"

            return "" 
        },
        inspectedTabState() {
            if(this.activeTab === "inspected")
                return "active"

            return "" 
        },
        items() {
            if(this.activeTab === "properties")
                return this.propertiesItems
            else if(this.activeTab === "inspected")
                return this.inspectedItems
        },
        sortItems() {
            if (this.sortValue === "byName") {
                return this.items.sort((a, b) => a.name.localeCompare(b.name))
            } else if (this.sortValue === "byDate") {
                return this.items.sort((a, b) => {
                    let aDate = new Date(a.date).getTime()
                    let bDate = new Date(b.date).getTime()

                    return aDate - bDate
                })
            }

            return this.items
        },
        filteredItems() {
            return this.sortItems.filter(item => item.name.toLowerCase().includes(this.searchValue.toLowerCase()))  
        },
    },
    methods: {
        changeSortValue(value) {
            this.sortValue = value
        }
    },
}
</script>

<style lang="sass">
@import ../../helpers/mixins

div.v-regular-inspections
    max-width: 1000px
    margin: 0 auto
    padding-bottom: 100px

    img.main-img
        width: 100%

    div.bottom-content
        max-width: 830px
        margin: 0 auto
        padding: 0 15px

    button.add-inspection-btn
        margin-bottom: 20px
        margin-top: 32px

    div.list-head
        display: flex
        justify-content: space-between
        align-items: center
        +mobile(500px)
            flex-direction: column
            align-items: start

        div.left
            +mobile(500px)
                width: 100%

        div.right
            display: flex
            align-items: center
            +mobile(500px)
                margin-top: 15px
                width: 100%

        span.sort-text
            font-size: 12px

    div.search-wrap
        position: relative
        display: inline-block
        +mobile(500px)
            width: 100%

        input.search-inp
            padding: 11px 12px
            font-size: 18px
            outline: none
            border: 1px solid #7ED3D4
            border-radius: 5px
            +mobile(500px)
                width: 100%

        img.magnifier-img
            position: absolute
            top: 50%
            right: 12px
            transform: translateY(-50%)

    div.tabs
        display: flex
        justify-content: space-around
        margin-top: 20px
        margin-bottom: 25px
        +mobile(500px)
            flex-direction: column
            align-items: center

        div.tab
            font-size: 16px
            line-height: 16px
            padding: 14px 16px
            text-transform: uppercase
            color: #124C7B
            box-shadow: inset 0px -1px 0px #7ED3D4
            cursor: pointer
            transition: 0.1s
            +mobile(500px)
                text-align: center
            +mobile(400px)
                width: 100%
            &.active
                box-shadow: inset 0px -2px 0px #124C7B

    div.c-sort-item
        border-radius: 20px
        background: #fff
        padding: 6px 12px
        margin: 0 6px
        display: flex
        justify-content: center
        align-items: center
        border: 1px solid #7ED3D4
        text-transform: uppercase
        color: #124C7B
        font-size: 12px
        cursor: pointer
        transition: 0.1s

        &.active
            background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
            img
                display: inline-block

        img
            margin-left: 7px
            display: none

</style>