<template>
    <div class="c-answer-item">
        <p class="question">{{ question }}</p>
        <p
            class="value"
            v-if="dataType.includes('Text') || dataType.includes('Text Area')"
        >
            {{ comment ? comment : "-" }}
        </p>
        <p class="value" v-if="dataType.includes('Checkbox')">
            {{ options }}
        </p>
        <div class="images" v-if="dataType.includes('Image')">
            <div
                v-for="item in images"
                :key="item.id"
                class="image-item"
            >
                <img :src="item.image" alt="image">
                <div class="labels">
                    <div
                        v-for="(item, index) in item.selectedLabels"
                        :key="index"
                        class="label-item"
                    >
                        <span>#{{ item }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="files" v-if="dataType.includes('File')">
            <div
                v-for="item in files"
                :key="item.id"
                class="file-item"
            >
                <img src="@/assets/file-icon.svg" alt="file" class="file-icon">
                <span>{{ item.filename }}</span>
            </div>
        </div>
        <div class="radios" v-if="dataType.includes('Radio')">
            <div class="radio-button" v-show="isChecked">
                <img src="@/assets/white-check.svg" alt="check">
                <span>Yes</span>
            </div>
            <div class="radio-button" v-show="!isChecked">
                <img src="@/assets/white-x.svg" alt="x">
                <span>No</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: [String, Number],
        question: [String, Number],
        dataType: [String, Array],
        comment: [String, Number],
        order: [String, Number],
        category: String,
        options: String,
        files: Array,
        images: Array,
        isChecked: [Number, String],
    },
    data: function() {
        return {}
    }
}
</script>

<style lang="sass">

div.c-answer-item
    padding-bottom: 20px
    margin-bottom: 20px
    border-bottom: 1px dashed rgba(18, 76, 123, .3)
    &:last-child
        border-bottom: none

    p.question
        margin: 0
        font-size: 20px
        line-height: 140%
        letter-spacing: 0.02em
        color: #434F5B
        margin-bottom: 15px

    p.value
        color: #111111
        font-size: 18px
        line-height: 120%
        margin: 0

    div.file-item
        background: #F8F8F8
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        border-radius: 28px
        display: inline-flex
        justify-content: center
        align-items: center
        padding: 12px 28px
        color: #124C7B
        margin: 10px 10px 10px 0
        img.file-icon
            margin-right: 12px
        span
            font-weight: 500
            font-size: 18px
            line-height: 136%
            letter-spacing: 0.04em
            text-transform: uppercase

    div.radios
        display: flex

        div.radio-button
            margin-right: 10px
            min-width: 252px
            min-height: 48px
            padding: 5px
            display: flex
            justify-content: center
            align-items: center
            background: #434F5B
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
            color: #fff
            font-weight: 500
            font-size: 18px
            line-height: 136%
            letter-spacing: 0.04em
            text-transform: uppercase

            img
                margin-right: 10px
                margin-left: -20px

            &:last-child
                margin-right: 0

    div.image-item
        margin-bottom: 15px
        img
            width: 100%

    div.label-item
        color: #124C7B
        letter-spacing: 0.04em
        font-size: 18px
        margin-right: 10px
        &:last-child
            margin: 0

    div.labels
        margin-top: 15px
        display: flex
        flex-wrap: wrap

</style>