import Request from "../helpers/Request"
import config from "../config"

export const getProperties = async () => new Request().get(
    config.pathAPI + `/properties`
)

export const addProperty = async (data) => new Request().post(
    config.pathAPI + `/properties`,
    data
)