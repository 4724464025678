<template>
    <div class="c-property-actions" @click.stop="">
        <div class="top">
            <div class="left">
                <p class="title" v-show="!isCampus">
                    Select Property
                    <sup>*</sup>
                </p>
                <p class="campus-title" v-show="isCampus">
                    Property Name
                </p>
                <div class="select-inp" @click.stop="showOpenList">
                    <div class="selected" v-if="!isSelectedProperty">
                        <p class="title">{{ selectedProperty.name }}</p>
                        <p class="address">{{ selectedProperty.address }}</p>
                    </div>
                    <span class="placeholder" v-show="isSelectedProperty">Select from a list</span>
                    <img src="@/assets/select-arrow.svg" alt="arrow">
                </div>
            </div>
            <div class="right">
                <button class="add-btn" @click="handleClick">
                    <span>Add Property</span>
                    <img src="@/assets/blue-plus.svg" alt="plus">
                </button>
            </div>
        </div>
        <div class="open-list" v-show="isShowOpenList">
            <div class="inp-wrap">
                <input
                    type="text"
                    placeholder="Type to search..."
                    class="inp"
                    v-model="searchValue"
                >
                <img src="@/assets/select-arrow.svg" alt="arrow" class="arrow-down">
            </div>
            <div class="list">
                <ListItem
                    v-for="item in filteredProperties"
                    :key="item.id"
                    :id="item.id"
                    :title="item.name"
                    :address="item.address"
                    :click="selectProperty"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ListItem from "../views/AddInspection/components/ListItem"

export default {
    props: {
        isCampus: Boolean,
        selectHandler: Function,
    },
    data: function() {
        return {
            isShowOpenList: false,
            searchValue: "",
        }
    },
    components: {
        ListItem,
    },
    async mounted() {
        this.$store.dispatch("properties/getProperties")
    },
    beforeDestroy() {
        document.removeEventListener("click", this.documentClick)
    },
    computed: {
        filteredProperties() {
            return this.$store.state.properties.properties.filter(item => item.name ? item.name.toLowerCase().includes(this.searchValue.toLowerCase()) : false)
        },
        selectedProperty() {
            return this.$store.state.properties.selectedProperty
        },
        isSelectedProperty() {
            return Object.keys(this.selectedProperty).length <= 0
        }
    },
    methods: {
        showOpenList() {
            this.isShowOpenList = true
            document.addEventListener("click", this.documentClick)
        },
        async selectProperty(id) {
            if(this.isCampus) {
                let { campusId, step } = this.$route.params
                await this.$store.dispatch("properties/selectProperty", { id, campusId })
            } else {
                this.$store.dispatch("properties/selectProperty", { id })
            }

            if(this.selectHandler)
                this.selectHandler()

            this.isShowOpenList = false
        },
        handleClick() {
            this.$store.commit("popups/show", "addProperty")
        },
        documentClick() {
            this.isShowOpenList = false
        }
    }
}
</script>

<style lang="sass">
@import ../helpers/mixins

div.c-property-actions
    margin-bottom: 20px

    div.top
        display: flex
        align-items: flex-end
        justify-content: space-between
        +mobile(500px)
            flex-direction: column

    div.left
        flex-grow: 1
        padding-right: 10px
        +mobile(500px)
            margin-bottom: 15px
            padding-right: 0

    div.left,
    div.right
        +mobile(500px)
            width: 100%

    p.campus-title
        color: #434F5B
        letter-spacing: 0.02em
        line-height: 140%
        font-size: 20px
        display: inline-block
        margin-bottom: 15px
        margin-top: 0

    p.title
        margin-bottom: 8px
        margin-top: 0
        color: #434F5B
        font-size: 14px
        line-height: 20px

    sup
        color: #EB5757
        font-size: 20px
        font-weight: 500

    div.select-inp
        display: flex
        justify-content: space-between
        align-items: center
        border: 1px solid #7ED3D4
        border-radius: 5px
        padding: 10px 12px
        cursor: pointer
        
        span.placeholder
            color: #ccc
            font-size: 18px

        p
            margin: 0

        p.title
            font-size: 18px
            color: #124C7B
            line-height: 25px
            margin-bottom: 0

        p.address
            font-size: 14px
            line-height: 20px
            color: #434F5B

    div.open-list
        width: calc(100% + 30px)
        position: absolute
        top: 6px
        left: -30px
        background: #fff
        border-radius: 4px
        box-shadow: 0px 0px 9px -4px #000000
        padding: 30px
        +mobile(500px)
            left: -15px
            padding: 15px
            top: 20px

        div.inp-wrap
            position: relative
            margin-bottom: 15px
            
        input.inp
            width: 100%
            border: 4px solid #7ED3D4
            border-radius: 5px
            padding: 10px 12px
            font-size: 18px
            outline: none
            margin-bottom: 0

        img.arrow-down
            position: absolute
            top: 50%
            right: 14px
            transform: translateY(-50%)

    button.add-btn
        display: flex
        justify-content: center
        align-items: center
        border: none
        outline: none
        background: #7ed3d4
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        border-radius: 22px
        color: #124C7B
        font-size: 18px
        line-height: 136%
        letter-spacing: 0.04em
        text-transform: uppercase
        padding: 10px 16px
        font-weight: 500
        cursor: pointer
        +mobile(500px)
            width: 100%
        img
            margin-left: 10px

</style>