export default {
    namespaced: true,
    state: () => ({
        userData: null, // Example { email: "dev@gmail.com", fullname: string }
        accessToken: null, // Random string
    }),
    mutations: {
        setUserData(state, data) {
            state.userData = data
            if(typeof localStorage !== "undefined")
                localStorage.setItem("data", JSON.stringify(data))
        },
        clearUserData(state) {
            state.userData = null
            localStorage.removeItem("data")
        },
        setTokens(state, data) {
            state.accessToken = data.accessToken
            if(typeof localStorage !== "undefined")
                localStorage.setItem("accessToken", data.accessToken)
        },
        clearTokens(state) {
            state.accessToken = null
            localStorage.removeItem("accessToken")
        },
    },
    getters: {
        isLogged(state) {
            if(!state.accessToken)
                return false

            return true
        }
    },
    actions: {
        async signin({ commit, state }, data) {
            commit("setUserData", { email: data.email, fullname: data.username })
            commit("setTokens", { accessToken: data.accessToken })
        },
        async signout({ commit }) {
            commit("clearTokens")
            commit("clearUserData")
        }
    }
}
