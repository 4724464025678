import { render, staticRenderFns } from "./AnwerItem.vue?vue&type=template&id=5107b9ed"
import script from "./AnwerItem.vue?vue&type=script&lang=js"
export * from "./AnwerItem.vue?vue&type=script&lang=js"
import style0 from "./AnwerItem.vue?vue&type=style&index=0&id=5107b9ed&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports