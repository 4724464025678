import Request from "../helpers/Request"
import config from "../config"

export const getCampusData = async (campusId) => new Request().get(
    config.pathAPI + `/campus/${campusId}`
)

export const getCampusAssessments = async () => new Request().get(
    config.pathAPI + `/campus`
)

export const getCampusQuestions = async (campusId) => new Request().get(
    config.pathAPI + `/campus/${campusId}/questions`
)

export const createCampusAssessment = async (data) => new Request().post(
    config.pathAPI + `/campus`,
    data
)

export const getProperties = async () => new Request().get(
    config.pathAPI + `/campus`
)

export const getCampusProperty = async (campusId) => new Request().get(
    config.pathAPI + `/campus/property/${campusId}`
)

export const setProperty = async (campusId, data) => new Request().put(
    config.pathAPI + `/campus/${campusId}/setProperty`,
    data,
)

export const setCheckAnswer = async (questionId, data) => new Request().put(
    config.pathAPI + `/campus/check/${questionId}`,
    data
)

export const saveAnswers = async (campusId, step, data) => new Request().put(
    config.pathAPI + `/campus/${campusId}/step/${step}/saveanswers`,
    data
)

// Entries

export const getEntries = async (campusId) => new Request().get(
    config.pathAPI + `/campus/${campusId}/entries`,
)

export const addEntry = async (questionId, data) => new Request().post(
    config.pathAPI + `/campus/entries/${questionId}`,
    data
)

export const selectEntry = async (answerId, data) => new Request().put(
    config.pathAPI + `/campus/entries/${answerId}`,
    data
)

// Upload Handlers

export const uploadFile = async (questionId, data) => new Request().post(
    config.pathAPI + `/campus/questions/${questionId}/file`,
    data
)

export const deleteFile = async (fileId) => new Request().delete(
    config.pathAPI + `/campus/questions/file/${fileId}`,
)

export const uploadImage = async (campusId, data) => new Request().post(
    config.pathAPI + `/campus/${campusId}/image`,
    data
)

// Labels
export const updateChoices = async (imageid, data) => new Request().post(
    config.pathAPI + `/campus/images/${imageid}`,
    data
)

export const getAnswers = async (campusId) => new Request().get(
    config.pathAPI + `/campus/${campusId}/answers`
)

export const uploadPropertyImage = async (campusId, data) => new Request().put(
    config.pathAPI + `/campus/property-image/${campusId}`,
    data
)

export const saveImageNote = async (imageId, data) => new Request().put(
    config.pathAPI + `/campus/images/${imageId}/notes`,
    data
)

// Report
export const saveReportFields = async (campusId, data) => new Request().put(
    config.pathAPI + `/campus/${campusId}/report-fields`,
    data
)

export const createReport = async (campusId) => new Request().get(
    config.pathAPI + `/campus/create-report/${campusId}`
)