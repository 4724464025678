<template>
    <div class="v-add-walk-inspection">
        <div class="spinner-wrap" v-show="!isDataLoaded">
            <Spinner />
        </div>
        <div class="head" v-show="isDataLoaded">
            <div class="head-top">
                <h2 class="title">Property Information</h2>
                <div class="steps">
                    <div class="prev-btn page-btn" @click="prevStep" v-if="step > 1">
                        <img src="@/assets/arrow-left.svg" alt="arrow">
                    </div>
                    <span>{{ step }} of 5</span>
                    <div class="next-btn page-btn" @click="nextStep" v-if="step < 5">
                        <img src="@/assets/arrow-left.svg" alt="arrow">
                    </div>
                </div>
            </div>
            <div class="progress-bar">
                <div class="progress" :style="`width: ${100 / 5 * step}%;`"></div>
            </div>
        </div>
        <form class="survey-form" @submit.prevent="handleSubmit">
            <div class="steps-wrap">
                <div class="property-wrap" v-show="isDataLoaded && $route.params.step < 2">
                    <PropertyActions :isCampus="true" v-show="!isEditAction" />
                    <div class="property-info" v-show="isEditAction">
                        <h2>Property</h2>
                        <p class="title">{{ property.name }}</p>
                        <p class="address">{{ property.address }}</p>
                    </div>
                    <div class="c-question-wrap">
                        <p class="question-title">Address</p>
                        <P class="value">{{ propertyInfo.address ? propertyInfo.address : "-" }}</P>
                    </div>
                    <div class="c-question-wrap">
                        <p class="question-title"># of Buildings</p>
                        <P class="value">{{ propertyInfo.numberOfBuildings ? propertyInfo.numberOfBuildings : "-" }}</P>
                    </div>
                    <div class="c-question-wrap">
                        <p class="question-title">Acreage</p>
                        <P class="value">{{ propertyInfo.acreage ? propertyInfo.acreage : "-" }}</P>
                    </div>
                    <div class="c-question-wrap">
                        <p class="question-title">Zoning</p>
                        <P class="value">{{ propertyInfo.zoning ? propertyInfo.zoning : "-" }}</P>
                    </div>
                    <div class="c-question-wrap">
                        <p class="question-title">Main Property Picture</p>
                        <div class="photos">
                            <div
                                v-for="item in propertyPictures"
                                :key="item.id"
                                class="image-item"
                            >
                                <img :src="item.image" alt="image">
                            </div>
                        </div>
                        <UploadButton
                            text="upload a photo"
                            name="property-picture"
                            img="aperture.svg"
                            btnClass="upload-btn"
                            :change="uploadPropertyPicture"
                        />
                    </div>
                </div>
                <Question
                    v-for="question in sortQuestions"
                    :key="question.id"
                    :questionId="question.id"
                    :question="question.question"
                    :dataType="question.dataType"
                    :entriesProp="question.options"
                    :files="question.files"
                    :comment="question.comment"
                    :imagesProp="question.images"
                    :isChecked="question.isChecked"
                />
            </div>
            <SubmitBtn :isActive="true" v-show="isDataLoaded"/>
        </form>
    </div>
</template>

<script>
import Question from "./components/Question"
import SubmitBtn from "./components/SubmitBtn"
import Spinner from "../../components/Spinner"
import PropertyActions from "../../components/PropertyActions"
import { saveAnswers, uploadPropertyImage, getCampusProperty } from "../../api/campus"
import UploadButton from "../../components/UploadButton"

export default {
    data: function() {
        return {
            property: {
                name: "",
                address: "",
            },
            questions: [],
        }
    },
    components: {
        Question,
        SubmitBtn,
        Spinner,
        PropertyActions,
        UploadButton,
    },
    async beforeMount() {
        let { campusId, step } = this.$route.params

        setTimeout(() => {
            this.$store.dispatch("questions/updateQuestions", { campusId, step })
        }, 600)

        if(this.isEditAction) {
            let property = await getCampusProperty(campusId)
            this.property = {
                name: property.propertyName,
                address: property.propertyAddress,
            }

            this.$store.commit("header/setData", {
                title: "Campus Assessments",
                showPrevBtn: true,
                showSaveBtn: true,
                showBackBtn: true,
                prevLink: "/campus-assessments"
            })
        } else {
            this.$store.commit("header/setData", {
                title: "Campus Assessments",
                showPrevBtn: true,
                showSaveBtn: true,
                prevLink: "/campus-assessments"
            })
        }

    },
    beforeDestroy() {
        this.$store.commit("questions/clearData")
    },
    computed: {
        step() {
            return this.$route.params.step
        },
        sortQuestions() {
            let { step } = this.$route.params

            return this.$store.getters[`questions/step${step}`].sort((a, b) => a.order - b.order)
        },
        isDataLoaded() {
            return this.sortQuestions.length > 0
        },
        propertyInfo() {
            return this.$store.state.properties.propertyInfo
        },
        propertyPictures() {
            return this.$store.state.properties.propertyInfo.propertyPictures
        },
        isEditAction() {
            return this.$route.params.action === "edit"
        },
    },
    methods: {
        async handleSubmit(e) {
            try {
                let { campusId, step } = this.$route.params
                let data = Object.fromEntries(new FormData(e.currentTarget))
                let nextStep = +step == 5 ? 5 : +step + 1

                for(let key in data) {
                    if(typeof data[key] !== "string" || !data[key])
                        delete data[key]
                }

                let response = await saveAnswers(campusId, step, data)

                if(step == 5)
                    this.$router.push(`/campus-assessments`)
                else
                    this.$router.push(`/campus-assessment/add/${campusId}/${nextStep}`)
            } catch (err) {
                console.error(err)
            }
        },
        uploadPropertyPicture(e) {
            let inp = e.currentTarget
            let file = inp.files[0]
            let reader = new FileReader()
            let { campusId } = this.$route.params

            reader.readAsDataURL(file)

            reader.onloadend = async () => {
                
                let response = await uploadPropertyImage(campusId, { filename: file.name, image: reader.result.split(",")[1] })

                this.$store.commit("properties/setPropertyPicture", response)
            }

            inp.value = ""
        },
        nextStep() {
            let nextStep = +this.step == 5 ? 5 : +this.step + 1

            this.$router.push(`/campus-assessment/add/${this.$route.params.campusId}/${nextStep}`)
        },
        prevStep() {
            let prevStep = +this.step > 1 ? +this.step - 1 : 1 

            this.$router.push(`/campus-assessment/add/${this.$route.params.campusId}/${prevStep}`)
        }
    }
}
</script>

<style lang="sass">
@import ../../helpers/mixins

div.v-add-walk-inspection
    max-width: 1000px
    margin: 0 auto
    padding: 0 15px 50px 15px

    div.c-question-wrap
        margin-bottom: 20px

    p.question-title
        color: #434F5B
        letter-spacing: 0.02em
        line-height: 140%
        font-size: 20px
        display: inline-block
        margin-bottom: 15px
        margin-top: 0

    div.spinner-wrap
        margin-top: 100px
        display: flex
        justify-content: center
        align-items: center

    div.property-wrap
        position: relative

    p.descr,
    p.value
        margin: 0 0 15px 0
    
    div.head-top
        display: flex
        justify-content: space-between
        align-items: center

        h2.title
            font-weight: 400
            color: #124C7B
            letter-spacing: 0.02em
            line-height: 140%
            font-size: 20px

    div.steps
        display: flex
        align-items: center

        span
            white-space: nowrap

        div.page-btn
            width: 44px
            height: 44px
            background: #7ED3D4
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
            padding: 10px
            display: flex
            justify-content: center
            align-items: center
            cursor: pointer

        div.prev-btn
            margin-right: 10px

        div.next-btn
            margin-left: 10px
            img
                transform: rotate(180deg)

    div.progress-bar
        height: 4px
        background: rgba(18, 76, 123, .2)

        div.progress
            background: #124C7B
            height: 4px
            width: 25%
            transition: 0.2s

    label.upload-btn
        margin-top: 20px

    div.steps-wrap
        padding-top: 40px
        max-width: 600px
        margin: 0 auto

    input.inp
        padding: 11px 12px
        font-size: 18px
        border: 1px solid #7ED3D4
        border-radius: 5px
        outline: none
        width: 100%
        margin-bottom: 15px

    select.select
        padding: 11px 12px
        font-size: 18px
        border: 1px solid #7ED3D4
        border-radius: 5px
        outline: none
        width: 100%

    label.answer-wrap
        span.text
            margin-bottom: 0

    div.checks-select
        p.text
            color: #434F5B
            letter-spacing: 0.02em
            line-height: 140%
            font-size: 20px
            display: inline-block
            margin-bottom: 20px

    ol.sub-list
        margin: 0
        li
            margin-top: 15px

    div.inp-group
        display: flex
        justify-content: space-between
        align-items: center

        input.inp
            width: 49%

    div.c-property-actions
        div.open-list
            min-width: calc(100% + 30px)

    div.photos
        img
            width: 100%

    div.property-info

        h2
            color: #434F5B
            letter-spacing: 0.02em
            line-height: 140%
            font-size: 20px
            display: inline-block
            margin-bottom: 15px
            margin-top: 0
            font-weight: 400
            margin-bottom: 0px

        p.title
            font-size: 18px
            color: #124C7B
            line-height: 25px
            margin-bottom: 0

        p.address
            font-size: 14px
            line-height: 20px
            color: #434F5B
            margin-top: 0

</style>