export default {
    namespaced: true,
    state: () => ({
        reportSent: false,
        reportSaved: false,
        inspectionCompleted: false,
        cancelInspection: false,
        addProperty: false,
        beforeExport: false,
    }),
    mutations: {
        show(state, name) {
            state[name] = true
        },
        hide(state, name) {
            state[name] = false
        },
    },
    getters: {},
    actions: {},
}
