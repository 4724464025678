<template>
    <div class="photos-wrap">
        <div class="photos">
            <div
                v-for="item in pictures"
                :key="item.id"
                class="image-item"
            >
                <img :src="item.image" alt="image">
                <Labels
                    :imageId="item.id"
                    :labelsProp="item.labels"
                    :selectedLabels="item.selectedLabels"
                />
                <form class="note-wrap" v-if="isNotes" @submit.prevent="handleSubmit(item.id)">
                    <textarea name="note" class="message">{{ item.note }}</textarea>
                    <div class="btn-wrap">
                        <button class="save-btn">
                            <BtnSpinner v-if="isShowSpinner"/>
                            <span v-if="!isShowSpinner">Save</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <UploadButton
            text="upload a photo"
            name="property-picture"
            img="aperture.svg"
            btnClass="upload-btn"
            :change="uploadPicture"
        />
    </div>
</template>

<script>
import UploadButton from "../../../components/UploadButton"
import Labels from "./Labels"
import { uploadImage, saveImageNote } from "../../../api/campus"
import BtnSpinner from "../../../components/BtnSpinner"
import { nanoid } from "nanoid"

export default {
    props: {
        onclick: Function,
        images: Array, // [ { id: string or number, image: base64 string } ]
        questionId: [String, Number],
        imagesProp: Array,
        isNotes: Boolean,
    },
    data: function() {
        return {
            pictures: this.imagesProp,
            isShowSpinner: false,
        }
    },
    components: {
        UploadButton,
        Labels,
        BtnSpinner,
    },
    methods: {
        uploadPicture(e) {
            try {
                let inp = e.currentTarget
                let file = inp.files[0]
                let reader = new FileReader()
                let { campusId } = this.$route.params
    
                reader.readAsDataURL(file)
    
                reader.onloadend = async () => {
                    let response = await uploadImage(campusId, { questionId: this.questionId,filename: file.name, image: reader.result.split(",")[1] })

                    this.pictures.push(response)
                }
    
                inp.value = ""
            } catch (err) {
                console.error(err)
            }
        },
        async handleSubmit(imageId) {
            let data = {}
            this.isShowSpinner = true

            ;[...event.currentTarget.elements].forEach(el => {
                if(el.name)
                    data[el.name] = el.value
            })

            let response = await saveImageNote(imageId, { note: data.note })

            this.isShowSpinner = false
        }
    }
}
</script>

<style lang="sass">

div.photos-wrap
    margin-bottom: 20px

    div.photos
        max-width: 600px
        margin: 0 auto
        
        img
            width: 100%

    div.image-item
        margin: 20px 0

    div.btn-wrap
        display: flex
        justify-content: center
        margin-top: 20px

    button.save-btn
        border: none
        outline: none
        border-radius: 20px
        background: #7ED3D4
        color: #124C7B
        font-size: 18px
        padding: 10px 30px
        cursor: pointer
        text-transform: uppercase
        font-weight: 500
        min-width: 104px
        min-height: 42px
        display: flex
        justify-content: center
        align-items: center

    textarea.message
        min-height: 128px
        width: 100%
        resize: none
        border: 1px solid #7ED3D4
        border-radius: 5px
        background: #fff
        font-size: 18px
        line-height: 120%
        outline: none
        padding: 10px
        margin-top: 10px

</style>