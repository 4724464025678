<template>
    <div class="u-popup-wrap">
        <div class="container">
            <div class="popup report-sent-popup">
                <div class="img-wrap">
                    <img src="@/assets/send.svg" alt="send">
                </div>
                <p class="text">
                    The inspection report was successfully
                    <br>sent to Wesley Community Development database
                </p>
                <Button
                    text="close"
                    leftImg="white-x.svg"
                    :isActive="true"
                    :click="closePopup"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../components/Button"

export default {
    data: function() {
        return {}
    },
    components: {
        Button,
    },
    methods: {
        closePopup() {
            this.$store.commit("popups/hide", { name: "reportSent" })
        }
    },
}
</script>

<style lang="sass">
    
div.report-sent-popup
    background: #fff
    padding: 50px
    max-width: 700px

    div.img-wrap
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 40px

    p.text
        font-size: 24px
        text-align: center
        color: #434F5B
        margin-top: 0
        margin-bottom: 40px
        letter-spacing: 0.02em

</style>