<template>
    <label class="c-question-btn">
        <input
            type="radio"
            :name="id"
            class="check"
            @click="onclick(id, text)"
            :checked="isActive"
        >
        <div :class="`button ${color}`">
            {{ text }}
        </div>
    </label>
</template>

<script>
export default {
    props: {
        id: [String, Number],
        text: String,
        isActive: [Boolean, Number],
        onclick: Function,
    },
    data: function() {
        return {}
    },
    computed: {
        color() {
            let text = this.text.toLowerCase()

            if(text === "pass" || text === "yes")
                return "blue"
            else if (text === "fail" || text === "no")
                return "red"
            else if (text === "n/a")
                return "gray"

            return ""
        },
    },
}
</script>

<style lang="sass">
    
label.c-question-btn

    input.check
        display: none
        &:checked
            ~ div
                color: #fff
            ~ div.blue
                background: #124C7B
            ~ div.red
                background: #AF292E
            ~ div.gray
                background: #434F5B

    div.button
        width: 160px
        height: 48px
        text-transform: uppercase
        display: flex
        justify-content: center
        align-items: center
        padding: 5px
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        font-weight: 500
        cursor: pointer
        &.blue
            color: #124C7B
            border: 2px solid #124C7B
        &.red
            color: #AF292E
            border: 2px solid #AF292E
        &.gray
            color: #434F5B
            border: 2px solid #434F5B

</style>