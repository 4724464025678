<template>
    <div class="u-popup-wrap">
        <div class="container">
            <form class="popup add-property-popup" @submit.prevent="handleSubmit">
                <div class="popup-head">
                    <div class="left">
                        <div class="close" @click="closePopup">
                            <img src="@/assets/blue-x.svg" alt="x">
                        </div>
                        <p class="popup-title">Add New Property</p>
                    </div>
                    <button class="save-btn">
                        <span>Save</span>
                        <img src="@/assets/cloud.svg" alt="cloud">
                    </button>
                </div>
                <div class="popup-body">
                    <div class="inp-wrap">
                        <p class="inp-title">
                            Property name:
                            <sup>*</sup>
                        </p>
                        <input
                            type="text"
                            class="inp"
                            name="name"
                            placeholder="Type here..."
                        >
                    </div>
                    <div class="inp-group">
                        <div class="inp-wrap">
                            <p class="inp-title">
                                Street 1
                                <sup>*</sup>
                            </p>
                            <input
                                type="text"
                                class="inp"
                                name="street1"
                                placeholder="Type here..."
                            >
                        </div>
                        <div class="inp-wrap">
                            <p class="inp-title">
                                Street 2
                                <sup>*</sup>
                            </p>
                            <input
                                type="text"
                                class="inp"
                                name="street2"
                                placeholder="Type here..."
                            >
                        </div>
                    </div>
                    <div class="inp-group">
                        <div class="inp-wrap">
                            <p class="inp-title">
                                City
                                <sup>*</sup>
                            </p>
                            <input
                                type="text"
                                class="inp"
                                name="city"
                                placeholder="Type here..."
                            >
                        </div>
                        <div class="inp-wrap">
                            <p class="inp-title">
                                State/Region
                                <sup>*</sup>
                            </p>
                            <input
                                type="text"
                                class="inp"
                                name="state"
                                placeholder="Type here..."
                            >
                        </div>
                    </div>
                    <div class="inp-group">
                        <div class="inp-wrap">
                            <p class="inp-title">
                                Postal Code
                                <sup>*</sup>
                            </p>
                            <input
                                type="text"
                                class="inp"
                                name="postalCode"
                                placeholder="Type here..."
                            >
                        </div>
                        <div class="inp-wrap">
                            <p class="inp-title">
                                Country
                                <sup>*</sup>
                            </p>
                            <input
                                type="text"
                                class="inp"
                                name="country"
                                placeholder="Type here..."
                            >
                        </div>
                    </div>
                    <button class="bottom-save-btn">
                        <span>Save</span>
                        <img src="@/assets/cloud.svg" alt="cloud">
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {}
    },
    methods: {
        closePopup() {
            this.$store.commit("popups/hide", "addProperty")
        },
        async handleSubmit(e) {
            let data = {}

            ;[...e.currentTarget.elements].forEach(item => {
                if(item.name)
                    data[item.name] = item.value
            })

            this.$store.dispatch("properties/addProperty", data)

            this.$store.commit("popups/hide", "addProperty")
        }
    },
}
</script>

<style lang="sass">
@import ../helpers/mixins

form.add-property-popup
    background: #fff
    min-width: 640px
    +mobile(650px)
        margin-top: 700px
        min-width: 100%

    div.popup-head
        padding: 15px
        display: flex
        justify-content: space-between
        align-items: center
        box-shadow: inset 0px -1px 0px rgba(18, 76, 123, 0.24)

    div.close
        width: 44px
        height: 44px
        display: flex
        justify-content: center
        align-items: center
        padding: 5px
        background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        cursor: pointer

    div.left
        display: flex
        align-items: center

        p.popup-title
            font-size: 22px
            line-height: 26px
            letter-spacing: 0.02em
            color: #124C7B
            margin: 0 0 0 15px
        
    button.save-btn
        display: flex
        justify-content: center
        align-items: center
        color: #fff
        outline: none
        border: none
        font-size: 18px
        font-weight: 500
        text-transform: uppercase
        padding: 10px 12px
        cursor: pointer
        background: #124C7B
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        img
            margin-left: 10px

    div.popup-body
        padding: 25px 50px
        +mobile(600px)
            padding: 20px

    div.inp-group
        display: flex
        justify-content: space-between
        +mobile(600px)
            flex-direction: column
        
        div.inp-wrap
            width: 50%
            padding: 0 5px
            +mobile(600px)
                padding: 0
                width: 100%

    div.inp-wrap
        margin-bottom: 25px

        p.inp-title
            font-size: 14px
            line-height: 146%
            color: #434F5B
            margin: 0
            margin-bottom: 5px
            sup
                color: #EB5757
                font-size: 20px
                font-weight: 500

        input.inp
            width: 100%
            padding: 12px
            font-size: 18px
            border: 1px solid #7ED3D4
            border-radius: 5px
            outline: none

    button.bottom-save-btn
        width: 100%
        background: #124C7B
        color: #fff
        padding: 10px
        outline: none
        display: flex
        justify-content: center
        align-items: center
        margin-top: 20px
        border: none
        font-size: 18px
        font-weight: 500
        padding: 16px
        cursor: pointer
        text-transform: uppercase
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
        img
            margin-left: 15px

</style>