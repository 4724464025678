<template>
    <label :class="`c-upload-btn ${btnClass ? btnClass: ''}`">
        <input type="file" :name="name" class="inp" @change="change">
        <p class="text">{{ text }}</p>
        <img :src="require(`@/assets/${img}`)" alt="img">
    </label>
</template>

<script>
export default {
    props: {
        text: String,
        name: String,
        img: String,
        btnClass: String,
        change: Function,
    },
    data: function() {
        return {}
    }
}
</script>

<style lang="sass">

label.c-upload-btn
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    background: #7ED3D4
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16)
    font-size: 18px
    line-height: 24px
    letter-spacing: 0.04em
    text-transform: uppercase
    color: #124C7B
    font-weight: 500
    padding: 5px
    height: 48px
    cursor: pointer

    p
        margin: 0

    img
        margin-left: 10px

    input
        display: none

</style>